import { original, produce } from 'immer';
import { getType } from 'typesafe-actions';

import { Action } from '../actions/index';
import { pushUserSuccess } from '../actions/user.actions';

export interface IUser {
  uid: string;
  createdAt: number;
  displayName: string;
  firstName: string;
  lastName: string;
  defaultCollection: string;
  email: string;
  emailVerified: boolean;
  isAnonymous: boolean;
  collections: {
    [key: string]:
      | {
          order: number;
        }
      | undefined;
  };
}

export type UsersState = {
  [key: string]: IUser | undefined;
};

export const userFactory = (obj = {}): IUser => ({
  uid: '',
  displayName: '',
  firstName: '',
  createdAt: 0,
  lastName: '',
  email: '',
  emailVerified: false,
  defaultCollection: '',
  isAnonymous: true,
  collections: {},
  ...obj,
});

export const initialState: UsersState = {};

export const userReducer = produce((draft: UsersState, action: Action) => {
  switch (action.type) {
    case getType(pushUserSuccess): {
      const { uid, user } = action.payload;
      draft[uid] = userFactory({
        ...original(draft[uid]),
        ...user,
      });
      return;
    }
  }
}, initialState);
