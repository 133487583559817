import { call, cancelled, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as boardExpiryActions from '../actions/boardExpiry.actions';
import { ErrorAction } from '../actions/';
import { generateValidCreateBoardExpiryQuery } from 'api/queries';
import * as api from 'api/firebase.api';

export default [
  takeEvery(getType(boardExpiryActions.addBoardExpiry), addBoardExpirySaga),
  takeEvery(getType(boardExpiryActions.subscribeBoardExpiry), subscribeBoardExpirySaga),
  takeEvery(getType(boardExpiryActions.pushBoardExpiry), pushBoardExpirySaga),
  takeEvery(getType(boardExpiryActions.updateBoardExpiry), updateBoardExpirySaga),
  takeEvery(getType(boardExpiryActions.deleteBoardExpiry), deleteBoardExpirySaga),
  takeEvery(
    [
      getType(boardExpiryActions.addBoardExpiryError),
      getType(boardExpiryActions.subscribeBoardExpiryError),
      getType(boardExpiryActions.pushBoardExpiryError),
      getType(boardExpiryActions.updateBoardExpiryError),
      getType(boardExpiryActions.deleteBoardExpiryError),
    ],
    handleErrorsSaga,
  ),
];

export function* subscribeBoardExpirySaga(
  action: ReturnType<typeof boardExpiryActions.subscribeBoardExpiry>,
) {
  const { boardKey } = action.payload;

  const chan = yield call(api.subscribeObject, 'boardExpiry', boardKey);

  try {
    while (true) {
      const { event, key, values }: api.FirebaseEvent<api.BoardExpiryServerNode> = yield take(chan);

      switch (event) {
        case 'value': {
          // Full node or null, indicating that boardExpiry does not exist and may be added
          if (api.isObject(values)) {
            yield put(boardExpiryActions.pushBoardExpiry(boardKey, values));
          } else if (values === null) {
            // If null is returned, there is no boardExpiry on the server
            yield put(boardExpiryActions.pushBoardExpiry(boardKey, {}));
          }

          break;
        }

        case 'child_changed': {
          if (!api.isObject(values) && key) {
            yield put(boardExpiryActions.pushBoardExpiry(boardKey, { [key]: values }));
          }
          break;
        }

        case 'child_added': {
          if (!api.isObject(values) && key) {
            yield put(boardExpiryActions.pushBoardExpiry(boardKey, { [key]: values }));
          }
          break;
        }

        case 'child_removed': {
          // When isDelete is deleted, we reset the state of the boardExpiry
          if (key === 'expiresAt') {
            yield put(boardExpiryActions.deleteBoardExpirySuccess(boardKey));
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(boardExpiryActions.subscribeBoardExpiryError(err));
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export function* addBoardExpirySaga(action: ReturnType<typeof boardExpiryActions.addBoardExpiry>) {
  try {
    const { uid, boardKey, expiresAt } = action.payload;

    yield call(api.createBoardExpiry, uid, boardKey, expiresAt);
  } catch (err) {
    yield put(boardExpiryActions.addBoardExpiryError(err));
  }
}

export function* pushBoardExpirySaga(
  action: ReturnType<typeof boardExpiryActions.pushBoardExpiry>,
) {
  try {
    const { boardKey, boardExpiry } = action.payload;
    yield put(boardExpiryActions.pushBoardExpirySuccess(boardKey, boardExpiry));
  } catch (err) {
    yield put(boardExpiryActions.pushBoardExpiryError(err));
  }
}

export function* updateBoardExpirySaga(
  action: ReturnType<typeof boardExpiryActions.updateBoardExpiry>,
) {
  try {
    const { boardKey, uid, expiresAt } = action.payload;

    yield call(api.updateBoardExpiry, uid, boardKey, expiresAt);
    // We don't update the board here as we await the server response
  } catch (err) {
    yield put(boardExpiryActions.updateBoardExpiryError(err));
  }
}

export function* deleteBoardExpirySaga(
  action: ReturnType<typeof boardExpiryActions.deleteBoardExpiry>,
) {
  try {
    const { boardKey } = action.payload;
    // Blocking call before deleting from local state
    yield call(api.deleteBoardExpiry, boardKey);
  } catch (err) {
    yield put(boardExpiryActions.deleteBoardExpiryError(err));
  }
}

export function* handleErrorsSaga(action: ErrorAction) {
  yield call(console.error, action.payload.error);
}
