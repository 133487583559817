import { original, produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';
import { ListOfObjectsWithOrderProperty } from '../api/firebase.api';
import { roles } from 'api/queries';

export interface IBoard {
  shortId?: string;
  title: string | '';
  lists: ListOfObjectsWithOrderProperty;
  createdAt: number;
  updatedAt: number;
  movedAt: number;
  lastUpdateUid?: string;
  createdByUid: string;
  role?: roles
}

export type BoardsState = {
  [key: string]: IBoard | undefined;
};

export const boardFactory = (obj = {}): IBoard => ({
  title: '',
  createdAt: 0,
  updatedAt: 0,
  movedAt: 0,
  lists: {},
  createdByUid: '',
  ...obj,
});

export const initialState: BoardsState = {};

export const boardReducer = produce((draft: BoardsState, action: actions.Action) => {
  switch (action.type) {
    case getType(actions.addBoardSuccess): {
      const { boardKey } = action.payload;
      draft[boardKey] = boardFactory();
      return;
    }

    case getType(actions.pushBoardSuccess):
    case getType(actions.updateBoardSuccess): {
      const { boardKey, board } = action.payload;

      draft[boardKey] = boardFactory({
        ...original(draft[boardKey]),
        ...board,
      });
      return;
    }

    case getType(actions.deleteBoardSuccess): {
      const { boardKey } = action.payload;
      delete draft[boardKey];
      return;
    }

    case getType(actions.pushListToBoardSuccess):
    case getType(actions.moveBoardListSuccess):
    case getType(actions.addListToBoardSuccess): {
      const { boardKey, listKey, order } = action.payload;

      const board = draft[boardKey];
      if (board) {
        board.lists[listKey] = { order };
      }

      return;
    }

    case getType(actions.deleteListFromBoardSuccess): {
      const { boardKey, listKey } = action.payload;

      if (draft[boardKey] && draft[boardKey]!.lists[listKey]) {
        delete draft[boardKey]!.lists[listKey];
      }

      return;
    }
  }
}, initialState);
