export const firstTheme = {
  colors: {
    primary: '#FD0261',
    secondary: '#1872DB',
    offline: { mode: '#FD0261', text: '#9B9B9B' },
    loading: '#9B9B9B',
    locked: '#1872DB',
    status: '#4A90E2',
    cards: '#FFFFFF',
    border: '#ececec',
    lightgrey: '#9B9B9B',
    gradient: {
      alert: 'linear-gradient(240deg, #f77062 0%, #FD0261 100%)',
      dashBtn: 'linear-gradient(to bottom, #35194E 0%, #3B1D51 100%)',
      primary: 'linear-gradient(to right, #ff5f6d, #fcb553)',
      secondary: 'linear-gradient(to top, #ad5389, #3c1053)',
    },
    darkMode: {
      default: 'rgba(255, 255, 255, 0.80)',
      primary: '#D53B76',
      secondary: '#1872DB',
      offline: { mode: '#FD0261', text: '#9B9B9B' },
      placeholder: 'rgb(99,99,102)',
      loading: '#9B9B9B',
      locked: '#1872DB',
      status: '#4A90E2',
      cards: 'rgb(28, 28, 30)',
      border: 'rgba(255, 255, 255, 0.1)',
      lightgrey: '#9B9B9B',
      darkGrey: '#121212',
    },
  },
  margins: {
    appBar: {
      mobile: '4rem',
      desktop: '6rem',
    },
  },
  typography: {
    title: 'Source Sans Pro, sans-serif',
    text: 'Roboto, Helvetica, Arial, sans-serif',
  },
  shadows: {
    one: '0 1px 0 rgba(0,0,0,0.05)',
    two: '0 3px 6px rgba(0,0,0,0.10), 0 3px 6px rgba(0,0,0,0.05)',
    three: '0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)',
    four: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    five: '0 19px 38px rgba(0,0,0,0.30), 0 15px 12px rgba(0,0,0,0.22)',
  },
  breakpoint: {
    desktop: '48em',
    HDesktop: '90em',
  },
  zIndex: {
    dropdown: 100,
    appBar: 101,
    signUp: 200,
    alertBar: 300,
    toolbar: 400,
    modal: 500,
  },
};
