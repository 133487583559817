import { all } from 'redux-saga/effects';

import itemSagas from '../sagas/item.sagas';
import boardSagas from '../sagas/board.sagas';
import boardExpirySagas from '../sagas/boardExpiry.sagas';
import listSagas from '../sagas/list.sagas';
import userSagas from '../sagas/user.sagas';
import userBoardsSagas from '../sagas/userBoards.sagas';
import collectionSagas from '../sagas/collection.sagas';
import presenceSagas from '../sagas/presence.sagas';
import authenticationSagas from '../sagas/authentication.sagas';
import notificationSagas from '../sagas/notification.sagas';

export default function* rootSaga() {
  yield all([
    ...collectionSagas,
    ...authenticationSagas,
    ...userSagas,
    ...userBoardsSagas,
    ...itemSagas,
    ...boardSagas,
    ...boardExpirySagas,
    ...listSagas,
    ...presenceSagas,
    ...notificationSagas,
  ]);
}
