import { takeEvery, takeLatest, fork, put, call, take, cancel, delay } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';

export default [
  takeEvery(getType(actions.addNotification), addNotificationSaga),
  takeEvery([getType(actions.addNotificationError)], notificationErrorsSaga),
];

export function* addNotificationSaga(action: ReturnType<typeof actions.addNotification>) {
  try {
    const { scope, level, title, timeout, message } = action.payload;

    yield put(actions.addNotificationSuccess(scope, level, title, message));

    if (timeout) {
      yield delay(timeout);
      yield put(actions.clearNotificationsSuccess(scope));
    }
  } catch (err) {
    yield put(actions.addNotificationError(err));
  }
}

export function* notificationErrorsSaga(action: actions.ErrorAction) {
  yield call(console.error, action.payload.error);
}
