import { takeEvery, takeLatest, call, take, put, cancel } from 'redux-saga/effects';

import { getType } from 'typesafe-actions';

import * as actions from '../actions';

import * as api from '../api/firebase.api';

export default [
  takeLatest(getType(actions.setLastActivity), setLastActivitySaga),
  takeEvery(getType(actions.subscribeConnectionStatus), subscribeConnectionStatusSaga),
  takeEvery(getType(actions.subscribePresence), subscribePresenceSaga),
  takeEvery(
    [
      getType(actions.subscribeConnectionStatusError),
      getType(actions.subscribePresenceError),
      getType(actions.setLastActivityError),
    ],
    presenceErrorsSaga,
  ),
];

export function* setLastActivitySaga(action: ReturnType<typeof actions.setLastActivity>) {
  try {
    const { timestamp } = action.payload;
    yield put(actions.setLastActivitySuccess(timestamp));
  } catch (error) {
    yield put(actions.setLastActivityError(error));
  }
}

export function* subscribeConnectionStatusSaga(
  action: ReturnType<typeof actions.subscribeConnectionStatus>,
) {
  try {
    const { uid, boardKey } = action.payload;

    const chan = yield call(api.subscribeConnectionStatus, uid, boardKey);

    while (true) {
      const { status, lastActive } = yield take(chan);
      yield put(actions.setStatus(status));
      yield put(actions.setLastActivity(lastActive));
    }
  } catch (error) {
    yield put(actions.subscribePresenceError(error));
  }
}

export function* subscribePresenceSaga(action: ReturnType<typeof actions.subscribePresence>) {
  try {
    const task = yield call(subscribeBoardUsersTask, action.payload.boardKey);
    yield take(getType(actions.unsubscribePresence));
    yield cancel(task);
    yield put(actions.pushBoardUsers([])); // For the moment an easy way to clear the state
  } catch (err) {
    yield put(actions.subscribePresenceError(err));
  }
}

export function* subscribeBoardUsersTask(boardKey: string) {
  try {
    const chan = yield call(api.subscribePresence, boardKey);

    while (true) {
      const { event, key, values } = yield take(chan);

      switch (event) {
        case 'value': {
          if (values && values.online) {
            yield put(actions.pushBoardUsers(values.online));
          }
          break;
        }

        case 'child_changed': {
          if (key === 'online') {
            yield put(actions.pushBoardUsers(values));
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (error) {
    yield put(actions.subscribePresenceError(error));
  } finally {
    console.log('presence unsubscribed');
  }
}

export function* presenceErrorsSaga(action: actions.ErrorAction) {
  yield call(console.error, action.payload.error);
}
