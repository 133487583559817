import { createAction } from 'typesafe-actions';

import { IBoard } from '../reducers/board.reducer';

export const subscribeShortId = createAction('SUBSCRIBE_SHORT_ID', resolve => (boardKey: string) =>
  resolve({ boardKey }),
);

export const subscribeShortIdError = createAction(
  'SUBSCRIBE_SHORT_ID_ERROR',
  resolve => (error: Error) => resolve(error),
);

export const getBoardKey = createAction(
  'GET_BOARD_KEY',
  resolve => (uid: string, shortId: string) => resolve({ uid, shortId }),
);

export const getBoardKeyError = createAction('GET_BOARD_KEY_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const addBoard = createAction('ADD_BOARD', resolve => (uid: string, collectionKey: string) =>
  resolve({ uid, collectionKey }),
);

export const addBoardSuccess = createAction(
  'ADD_BOARD_SUCCESS',
  resolve => (uid: string, boardKey: string) => resolve({ uid, boardKey }),
);

export const addBoardError = createAction('ADD_BOARD_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const pushBoard = createAction('PUSH_BOARD', resolve => (uid: string, boardKey: string) =>
  resolve({ uid, boardKey }),
);

export const pushBoardSuccess = createAction(
  'PUSH_BOARD_SUCCESS',
  resolve => (boardKey: string, board: Partial<IBoard>) => resolve({ boardKey, board }),
);

export const pushBoardError = createAction('PUSH_BOARD_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const subscribeBoard = createAction(
  'SUBSCRIBE_BOARD',
  resolve => (uid: string, boardKey: string) => resolve({ uid, boardKey }),
);

export const subscribeBoardError = createAction(
  'SUBSCRIBE_BOARD_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const updateBoard = createAction(
  'UPDATE_BOARD',
  resolve => (uid: string, boardKey: string, board: Partial<IBoard>) =>
    resolve({ uid, boardKey, board }),
);

export const updateBoardSuccess = createAction(
  'UPDATE_BOARD_SUCCESS',
  resolve => (boardKey: string, board: Partial<IBoard>) => resolve({ boardKey, board }),
);

export const updateBoardError = createAction('UPDATE_BOARD_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const deleteBoard = createAction('DELETE_BOARD', resolve => (boardKey: string) =>
  resolve({ boardKey }),
);
export const deleteBoardSuccess = createAction(
  'DELETE_BOARD_SUCCESS',
  resolve => (boardKey: string) => resolve({ boardKey }),
);
export const deleteBoardError = createAction('DELETE_BOARD_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const addListToBoard = createAction(
  'ADD_LIST_TO_BOARD',
  resolve => (boardKey: string, listKey: string, index: number = -1) =>
    resolve({ boardKey, listKey, index }),
);

export const addListToBoardSuccess = createAction(
  'ADD_LIST_TO_BOARD_SUCCESS',
  resolve => (boardKey: string, listKey: string, order: number) =>
    resolve({ boardKey, listKey, order }),
);

export const addListToBoardError = createAction(
  'ADD_LIST_TO_BOARD_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushListToBoard = createAction(
  'PUSH_BOARD_LIST',
  resolve => (boardKey: string, listKey: string, index: number = -1) =>
    resolve({ boardKey, listKey, index }),
);

export const pushListToBoardSuccess = createAction(
  'PUSH_BOARD_LIST_SUCCESS',
  resolve => (boardKey: string, listKey: string, order: number) =>
    resolve({ boardKey, listKey, order }),
);

export const pushListToBoardError = createAction(
  'PUSH_LIST_TO_BOARD_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const deleteListFromBoard = createAction(
  'DELETE_LIST_FROM_BOARD',
  resolve => (boardKey: string, listKey: string) => resolve({ boardKey, listKey }),
);

export const deleteListFromBoardSuccess = createAction(
  'DELETE_LIST_FROM_BOARD_SUCCESS',
  resolve => (boardKey: string, listKey: string) => resolve({ boardKey, listKey }),
);

export const deleteListFromBoardError = createAction(
  'DELETE_LIST_FROM_BOARD_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

// We provide the index value for the reducer,
// but use insertAfterKey for the server
export const moveBoardList = createAction(
  'MOVE_BOARD_LIST',
  resolve => (
    uid: string,
    boardKey: string,
    listKey: string,
    order: number,
    insertAfterKey: string | undefined,
    persist: boolean = false,
  ) => resolve({ uid, boardKey, listKey, order, insertAfterKey, persist }),
);

export const moveBoardListSuccess = createAction(
  'MOVE_BOARD_LIST_SUCCESS',
  resolve => (uid: string, boardKey: string, listKey: string, order: number) =>
    resolve({ boardKey, listKey, order, uid }),
);

export const moveBoardListError = createAction('MOVE_BOARD_LIST_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const subscribeBoardLists = createAction(
  'SUBSCRIBE_BOARD_LISTS',
  resolve => (boardKey: string) => resolve({ boardKey }),
);

export const subscribeBoardListsError = createAction(
  'SUBSCRIBE_BOARD_LISTS_ERROR',
  resolve => (error: Error) => resolve({ error }),
);
