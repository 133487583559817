import { call, cancelled, put, take, takeEvery, takeLatest, fork } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import * as api from '../api/firebase.api';
import { IUser } from '../reducers/user.reducer';
import { SHORT_ID_DID_NOT_RESOLVE } from '../config/constants';
import { deleteBoardFromCollection } from '../actions';

export default [
  takeEvery(getType(actions.subscribeShortId), subscribeShortIdSaga),
  takeEvery(getType(actions.subscribeBoard), subscribeBoardSaga),
  takeEvery(getType(actions.deleteBoard), deleteBoardSaga),
  takeEvery(getType(actions.updateBoard), updateBoardSaga),
  takeLatest(getType(actions.moveBoardList), moveBoardListSaga),
  takeEvery(getType(actions.addBoard), addBoardSaga),
  takeEvery(getType(actions.getBoardKey), getBoardKeySaga),
  takeEvery(getType(actions.subscribeBoardLists), subscribeBoardListsSaga),
  takeEvery(getType(actions.addListToBoard), addListToBoardSaga),
  takeEvery(getType(actions.deleteListFromBoard), deleteListFromBoardSaga),
  takeEvery(
    [
      getType(actions.addBoardError),
      getType(actions.getBoardKeyError),
      getType(actions.deleteBoardError),
      getType(actions.updateBoardError),
      getType(actions.subscribeBoardError),
      getType(actions.addListToBoardError),
      getType(actions.deleteListFromBoardError),
      getType(actions.moveBoardListError),
      getType(actions.subscribeBoardListsError),
      getType(actions.subscribeBoardError),
      getType(actions.subscribeShortIdError),
    ],
    handleErrorsSaga,
  ),
];

export function* subscribeShortIdSaga(action: ReturnType<typeof actions.subscribeShortId>) {
  const { boardKey } = action.payload;

  const chan = yield call(api.subscribeObject, 'boards', boardKey);

  try {
    while (true) {
      const { key, values } = yield take(chan);

      // When we are waiting for the server to create short_id for us
      // we await child_added event for 'short_id'
      if (key === 'short_id') {
        yield put(actions.pushBoardSuccess(boardKey, { shortId: values }));
        yield chan.close();
      }

      // When server already has that info, we fetch it (we subscribe to /data elsewhere,
      // but shortId is at the root of /boards/$boardKey)
      if (values.short_id) {
        yield put(actions.pushBoardSuccess(boardKey, { shortId: values.short_id }));
        yield chan.close();
      }
    }
  } catch (e) {
    yield put(actions.subscribeShortIdError(e));
  }
}

export function* moveBoardListSaga(action: ReturnType<typeof actions.moveBoardList>) {
  try {
    const { boardKey, listKey, insertAfterKey, uid, order, persist } = action.payload;

    yield put(actions.moveBoardListSuccess(uid, boardKey, listKey, order));

    if (persist) {
      yield call(
        api.moveObjectChild,
        uid,
        'boards',
        boardKey,
        null,
        'lists',
        listKey,
        insertAfterKey,
      );
    }
  } catch (err) {
    yield put(actions.moveBoardListError(err));
  }
}

export function* addBoardSaga(action: ReturnType<typeof actions.addBoard>) {
  try {
    const { uid, collectionKey } = action.payload;
    const boardKey = yield call(api.generateKey, 'boards');

    yield call(api.createBoardAndOwnerPermissions, uid, boardKey, collectionKey);

    yield put(actions.addBoardSuccess(uid, boardKey));
    yield put(actions.selectBoard(boardKey));
  } catch (err) {
    yield put(actions.addBoardError(err));
  }
}

export function* getBoardKeySaga(action: ReturnType<typeof actions.getBoardKey>) {
  try {
    const { uid, shortId } = action.payload;

    if (!uid) {
      throw new Error(
        'UID not available yet. This indicates that user authentication has not completed and we may therefore not be able to fetch the boardKey',
      );
    }

    const boardKey = yield call(api.getBoardKey, shortId);
    yield put(actions.selectBoard(boardKey));
  } catch (err) {
    yield put(actions.getBoardKeyError(err));
  }
}

export function* deleteBoardSaga(action: ReturnType<typeof actions.deleteBoard>) {
  try {
    const { boardKey } = action.payload;
    // Blocking call before deleting from local state
    yield call(api.deleteBoard, boardKey);
    // Delete from boards, but do not yet delete from collections
    // as we need to wait for the server to do this for us
    yield put(actions.deleteBoardSuccess(boardKey));
  } catch (err) {
    yield put(actions.deleteBoardError(err));
  }
}

export function* updateBoardSaga(action: ReturnType<typeof actions.updateBoard>) {
  try {
    const { uid, boardKey, board } = action.payload;
    yield put(actions.updateBoardSuccess(boardKey, board));
    yield call(api.updateObject, 'boards', uid, boardKey, board);
  } catch (err) {
    yield put(actions.updateBoardError(err));
  }
}

export function* subscribeBoardSaga(action: ReturnType<typeof actions.subscribeBoard>) {
  const { boardKey, uid } = action.payload;

  let boardPermissionsRole = yield call(api.getBoardUserRole, uid, boardKey);

  if (!boardPermissionsRole) {
    // If user does not yet have permissions, we fetch the defaultCollection
    // where we will add the board to
    const userData: IUser = yield call(api.getUserData, uid);

    // At the moment we're adding every user as an author
    // which unless board === readOnly or privat, users can do
    yield call(api.createBoardPermissions, uid, 'authors', boardKey, userData.defaultCollection);
    boardPermissionsRole = yield call(api.getBoardUserRole, uid, boardKey);
  }

  yield put(actions.pushBoardSuccess(boardKey, { role: boardPermissionsRole }))

  const chan = yield call(api.subscribeObject, 'boards', boardKey, 'data');

  try {
    while (true) {
      const { event, key, values }: api.FirebaseEvent<api.BoardDataServerNode> = yield take(chan);

      switch (event) {
        case 'value': {
          if (api.isDataObject(key, values)) {
            yield put(actions.pushBoardSuccess(boardKey, values));
          } else {
            yield put(
              actions.subscribeBoardError(new Error('Key returned from server is not in use')),
            );
          }
          break;
        }

        case 'child_changed': {
          if (key && api.isChildNode(key, values)) {
            yield put(actions.pushBoardSuccess(boardKey, { [key]: values }));
          }
          break;
        }

        case 'child_added': {
          if (key && api.isChildNode(key, values)) {
            yield put(actions.pushBoardSuccess(boardKey, { [key]: values }));
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.subscribeBoardError(err));
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export function* subscribeBoardListsSaga(action: ReturnType<typeof actions.subscribeBoardLists>) {
  const { boardKey } = action.payload;

  const chan = yield call(api.subscribeObject, 'boards', boardKey, 'lists', {
    orderBy: 'order',
  });


  try {
    while (true) {
      const { event, key, values }: api.ChildRelationResponse = yield take(chan);

      switch (event) {
        case 'value': {
          if (key === 'lists') {
            if (values && !api.isObjectWithOrderProperty(values)) {
              yield put(actions.pushBoardSuccess(boardKey, { lists: values }));
            } else {
              throw new Error('Values returned by server are null or not processed');
            }
          } else {
            throw new Error('Key returned from server is not in use');
          }
          break;
        }

        case 'child_added': {
          if (values && api.isObjectWithOrderProperty(values)) {
            yield put(actions.addListToBoard(boardKey, key, values.order));
          } else {
            throw new Error('Values returned by server are null or not processed');
          }

          break;
        }

        case 'child_changed': {
          if (values && api.isObjectWithOrderProperty(values)) {
            yield put(actions.moveBoardList('', boardKey, key, values.order, undefined, false));
          } else {
            throw new Error('Values returned by server are null or not processed');
          }

          break;
        }

        case 'child_removed': {
          yield put(actions.deleteListFromBoard(boardKey, key));
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.subscribeBoardListsError(err));
  } finally {
    if (yield cancelled()) {
      chan.close();
    }
  }
}

export function* addListToBoardSaga(action: ReturnType<typeof actions.addListToBoard>) {
  try {
    const { boardKey, listKey, index } = action.payload;

    yield put(actions.addListToBoardSuccess(boardKey, listKey, index));
  } catch (err) {
    yield put(actions.addListToBoardError(err));
  }
}

export function* deleteListFromBoardSaga(action: ReturnType<typeof actions.deleteListFromBoard>) {
  try {
    const { boardKey, listKey } = action.payload;
    yield put(actions.deleteListFromBoardSuccess(boardKey, listKey));
  } catch (err) {
    yield put(actions.deleteListFromBoardError(err));
  }
}

export function* handleErrorsSaga(action: actions.ErrorAction) {
  yield call(console.error, action.payload.error);
}
