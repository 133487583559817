import produce from 'immer';
import { getType } from 'typesafe-actions';

import { Action } from '../actions/index';
import { pushBoardUsers, setLastActivitySuccess, setStatus } from '../actions/presence.actions';

export type PresenceState = {
  status?: 'connected' | 'disconnected' | '';
  lastActive?: number;
  users?: {
    online: Array<string>;
    offline: Array<string>;
  };
};

export const presenceFactory = (obj = {}): PresenceState => ({
  status: '',
  lastActive: 0,
  users: {
    online: [],
    offline: [],
  },
  ...obj,
});

export const initialState: PresenceState = presenceFactory();

export const presenceReducer = produce((draft: PresenceState, action: Action) => {
  switch (action.type) {
    case getType(setStatus): {
      const { status } = action.payload;
      draft.status = status;
      return;
    }

    // NOTE: Currently only pushing online users
    case getType(pushBoardUsers): {
      const { online } = action.payload;

      if (draft.users) {
        draft.users.online = online;
      }

      return;
    }

    case getType(setLastActivitySuccess): {
      const { timestamp } = action.payload;
      draft.lastActive = timestamp;
      return;
    }
  }
}, initialState);
