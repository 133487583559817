import { createAction } from 'typesafe-actions';

import { IUser } from '../reducers/user.reducer';

export const authenticate = createAction('AUTHENTICATE', resolve => () => resolve());

export const authenticateSuccess = createAction('AUTHENTICATE_SUCCESS', resolve => (uid: string) =>
  resolve({ uid })
);

export const authenticateError = createAction('AUTHENTICATE_ERROR', resolve => (error: Error) =>
  resolve({ error })
);

export const observeAuthentication = createAction('OBSERVE_AUTHENTICATION');

export const signInWithEmailAndPassword = createAction(
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD',
  resolve => (email: string, password: string) => resolve({ email, password })
);

export const signInWithEmailAndPasswordSuccess = createAction(
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_SUCCESS',
  resolve => (user: IUser) => resolve({ user })
);

export const signInWithEmailAndPasswordError = createAction(
  'SIGN_IN_WITH_EMAIL_AND_PASSWORD_ERROR',
  resolve => (error: Error) => resolve(error)
);

export const signOut = createAction('SIGN_OUT', resolve => () => resolve());

export const signOutSuccess = createAction('SIGN_OUT_SUCCESS', resolve => () => resolve());

export const signInAnonymously = createAction('SIGN_IN_ANONYMOUSLY', resolve => () => resolve({}));

export const signInAnonymouslySuccess = createAction(
  'SIGN_IN_ANONYMOUSLY_SUCCESS',
  resolve => (user: IUser) => resolve({ user })
);

export const signInAnonymouslyError = createAction(
  'SIGN_IN_ANONYMOUSLY_ERROR',
  resolve => (error: Error) => resolve({ error })
);

export const registerUserWithEmailAndPassword = createAction(
  'REGISTER_USER_WITH_EMAIL_AND_PASSWORD',
  resolve => (email: string, password: string) => resolve({ email, password })
);

export const registerUserWithEmailAndPasswordSuccess = createAction(
  'REGISTER_USER_WITH_EMAIL_AND_PASSWORD_SUCCESS',
  resolve => (user: IUser) => resolve({ user })
);

export const registerUserWithEmailAndPasswordError = createAction(
  'REGISTER_USER_WITH_EMAIL_AND_PASSWORD_ERROR',
  resolve => (error: Error) => resolve({ error })
);

export const sendPasswordResetEmail = createAction(
  'SEND_PASSWORD_RESET_EMAIL',
  resolve => (email: string) => resolve({ email })
);

export const sendPasswordResetEmailSuccess = createAction(
  'SEND_PASSWORD_RESET_EMAIL_SUCCESS',
  resolve => (email: string) => resolve({ email })
);
