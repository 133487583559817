import { takeEvery, put, call, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';

import * as api from '../api/firebase.api';

export default [
  takeEvery(getType(actions.pushUser), pushUserSaga),
  takeEvery(getType(actions.subscribeUser), subscribeUserSaga),
  takeEvery(getType(actions.updateFirebaseUserProfile), updateFirebaseUserProfileSaga),
  takeEvery(getType(actions.updateEmail), updateEmailSaga),
  takeEvery(getType(actions.updatePassword), updatePasswordSaga),
  takeEvery([getType(actions.pushUserError)], handleErrors),
];

export function* pushUserSaga(action: ReturnType<typeof actions.pushUser>) {
  try {
    yield put(actions.pushUserSuccess(action.payload.uid, action.payload.user));
  } catch (error) {
    yield put(actions.pushUserError(error));
  }
}

export function* subscribeUserSaga(action: ReturnType<typeof actions.subscribeUser>) {
  const { uid } = action.payload;

  const chan = yield call(api.subscribeObject, 'users', uid, 'data');

  try {
    while (true) {
      const { key, event, values }: api.FirebaseEvent<api.UserDataServerNode> = yield take(chan);

      switch (event) {
        case 'value': {
          if (api.isDataObject(key, values)) {
            yield put(actions.pushUserSuccess(uid, values));
          } else {
            throw new Error('Key returned from server is not handled here');
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.pushUserError(err));
  }
}

export function* updateFirebaseUserProfileSaga(
  action: ReturnType<typeof actions.updateFirebaseUserProfile>,
) {
  try {
    yield call(api.updateFirebaseUserProfile, action.payload.user);
  } catch (err) {
    yield put(actions.pushError('account', 'updateFirebaseUserProfile', err.message));
  }
}

export function* updateEmailSaga(action: ReturnType<typeof actions.updateEmail>) {
  try {
    yield put(actions.clearNotificationsSuccess('account'));
    yield put(actions.setLoadingState('updateEmail', true));
    yield call(api.updateEmail, action.payload.email, action.payload.password);
    yield put(actions.setLoadingState('updateEmail', false));
    yield put(
      actions.addNotificationSuccess('account', 'info', 'Updated', 'email address updated'),
    );
  } catch (err) {
    yield put(actions.pushError('account', 'updateEmail', err.message));
    yield put(actions.setLoadingState('updateEmail', false));
  }
}

export function* updatePasswordSaga(action: ReturnType<typeof actions.updatePassword>) {
  try {
    yield put(actions.clearNotificationsSuccess('account'));
    yield put(actions.setLoadingState('updatePassword', true));
    yield call(api.updatePassword, action.payload.currentPassword, action.payload.nextPassword);
    yield put(actions.setLoadingState('updatePassword', false));
    yield put(actions.addNotificationSuccess('account', 'info', 'Updated', 'password updated'));
  } catch (err) {
    yield put(actions.pushError('account', 'updatePassword', err.message));
    yield put(actions.setLoadingState('updatePassword', false));
  }
}

export function* handleErrors(error: actions.ErrorAction) {
  yield call(console.error, error);
}
