import { createAction } from 'typesafe-actions';

import { IUser } from '../reducers/user.reducer';

export const pushUser = createAction('PUSH_USER', resolve => (uid: string, user: Partial<IUser>) =>
  resolve({ uid, user }),
);

export const pushUserSuccess = createAction(
  'PUSH_USER_SUCCESS',
  resolve => (uid: string, user: Partial<IUser>) => resolve({ uid, user }),
);

export const pushUserError = createAction('PUSH_USER_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const subscribeUser = createAction('SUBSCRIBE_USER', resolve => (uid: string) =>
  resolve({ uid }),
);

export const subscribeUserError = createAction('SUBSCRIBE_USER_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const updateFirebaseUserProfile = createAction(
  'UPDATE_FIREBASE_USER_PROFILE',
  resolve => (user: firebase.User) => resolve({ user }),
);

export const updateEmail = createAction(
  'UPDATE_EMAIL',
  resolve => (email: string, password: string) => resolve({ email, password }),
);

export const updatePassword = createAction(
  'UPDATE_PASSWORD',
  resolve => (currentPassword: string, nextPassword: string) =>
    resolve({ currentPassword, nextPassword }),
);
