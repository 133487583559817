import { takeEvery, put, call, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';

import * as api from '../api/firebase.api';

export default [
  takeEvery(getType(actions.pushCollectionBoards), pushCollectionBoardsSaga),
  takeEvery(getType(actions.subscribeCollectionBoards), subscribeCollectionBoardsSaga),
  takeEvery(getType(actions.addBoardToCollection), addBoardToCollectionSaga),
  takeEvery(getType(actions.deleteBoardFromCollection), deleteBoardFromCollectionSaga),
  takeEvery(getType(actions.pushBoardToCollection), pushBoardToCollectionSaga),
  takeEvery(getType(actions.moveCollectionBoard), moveCollectionBoardSaga),
  takeEvery(
    [
      getType(actions.pushCollectionBoardsError),
      getType(actions.addBoardToCollectionError),
      getType(actions.pushBoardToCollectionError),
      getType(actions.moveCollectionBoardError),
      getType(actions.deleteBoardFromCollectionError),
      getType(actions.subscribeCollectionBoardsError),
    ],
    handleErrors,
  ),
];

export function* moveCollectionBoardSaga(action: ReturnType<typeof actions.moveCollectionBoard>) {
  try {
    const { uid, collectionKey, boardKey, order, persist, insertAfterKey } = action.payload;

    yield put(actions.moveCollectionBoardSuccess(collectionKey, boardKey, order));

    if (persist) {
      yield call(
        api.moveObjectChild,
        uid,
        'collections',
        collectionKey,
        null,
        'boards',
        boardKey,
        insertAfterKey,
      );
    }
  } catch (error) {
    yield put(actions.moveCollectionBoardError(error));
  }
}

export function* pushCollectionBoardsSaga(action: ReturnType<typeof actions.pushCollectionBoards>) {
  try {
    yield put(
      actions.pushCollectionBoardsSuccess(action.payload.collectionKey, action.payload.boardKeys),
    );
  } catch (error) {
    yield put(actions.pushCollectionBoardsError(error));
  }
}

export function* subscribeCollectionBoardsSaga(
  action: ReturnType<typeof actions.subscribeCollectionBoards>,
) {
  const { collectionKey, limit, childValue, childKey } = action.payload;

  const chan = yield call(api.subscribeObject, 'collections', collectionKey, 'boards', {
    sort: 'ASC',
    childValue,
    childKey,
    limit,
    orderBy: 'order',
  });

  try {
    while (true) {
      const { key, event, values }: api.ChildRelationResponse = yield take(chan);

      switch (event) {
        case 'value': {
          if (key === 'boards') {
            if (values && !api.isObjectWithOrderProperty(values)) {
              yield put(actions.pushCollectionBoardsSuccess(collectionKey, values));
            } else {
              throw new Error('Values returned by server are null or not processed');
            }
          } else {
            throw new Error('Key returned from server is not in use');
          }
          break;
        }

        case 'child_added': {
          if (key && values && api.isObjectWithOrderProperty(values)) {
            yield put(actions.pushBoardToCollection(collectionKey, key, values.order));
          } else {
            throw new Error('Values returned byu server are null or not processed');
          }
          break;
        }

        case 'child_changed': {
          if (key && values && api.isObjectWithOrderProperty(values)) {
            yield put(
              actions.moveCollectionBoard('', collectionKey, key, values.order, undefined, false),
            );
          } else {
            throw new Error('Values returned by server are null or not processed');
          }
          break;
        }

        case 'child_removed': {
          yield put(actions.deleteBoardFromCollection(collectionKey, key));
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.subscribeCollectionBoardsError(err));
  } finally {
    chan.off();
  }
}

export function* addBoardToCollectionSaga(action: ReturnType<typeof actions.addBoardToCollection>) {
  try {
    // Writing to API is handled by board creation flow
    yield put(
      actions.addBoardToCollectionSuccess(
        action.payload.collectionKey,
        action.payload.boardKey,
        action.payload.order,
      ),
    );
  } catch (err) {
    yield put(actions.addBoardToCollectionError(err));
  }
}

export function* deleteBoardFromCollectionSaga(
  action: ReturnType<typeof actions.deleteBoardFromCollection>,
) {
  try {
    const { boardKey, collectionKey } = action.payload;
    yield put(actions.deleteBoardFromCollectionSuccess(collectionKey, boardKey));
  } catch (err) {
    yield put(actions.deleteBoardFromCollectionError(err));
  }
}

export function* pushBoardToCollectionSaga(
  action: ReturnType<typeof actions.pushBoardToCollection>,
) {
  try {
    // Writing to API is handled by board creation flow
    yield put(
      actions.pushBoardToCollectionSuccess(
        action.payload.collectionKey,
        action.payload.boardKey,
        action.payload.order,
      ),
    );
  } catch (err) {
    yield put(actions.pushBoardToCollectionError(err));
  }
}

export function* handleErrors(error: actions.ErrorAction) {
  yield call(console.error, error);
}
