import { original, produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';

import { ListOfObjectsWithOrderAndStarredProperty } from '../api/firebase.api';

// At this point, we won't have more information about
// a collection because we use a lone default collection
export interface ICollection {
  boards: ListOfObjectsWithOrderAndStarredProperty;
}

export type CollectionsState = {
  [key: string]: ICollection | undefined;
};

export const collectionFactory = (obj = {}): ICollection => ({
  boards: {},
  ...obj,
});

export const initialState: CollectionsState = {};

export const collectionReducer = produce((draft: CollectionsState, action: actions.Action) => {
  // At this point we are only handling the 'boards' subnode
  // of collections (and for now, only defaultCollection)
  switch (action.type) {
    case getType(actions.pushCollectionBoardsSuccess): {
      const { collectionKey, boardKeys } = action.payload;

      draft[collectionKey] = collectionFactory({
        ...original(draft[collectionKey]),
      });

      const collection = draft[collectionKey];

      if (collection) {
        collection.boards = { ...collection.boards, ...boardKeys };
      }

      return;
    }

    case getType(actions.pushBoardToCollectionSuccess):
    case getType(actions.moveCollectionBoardSuccess):
    case getType(actions.addBoardToCollectionSuccess): {
      const { collectionKey, boardKey, order } = action.payload;
      const collection = draft[collectionKey];

      if (collection) {
        collection.boards[boardKey] = { order };
      }
      return;
    }

    case getType(actions.deleteBoardFromCollectionSuccess): {
      const { collectionKey, boardKey } = action.payload;

      if (draft[collectionKey] && draft[collectionKey]!.boards[boardKey]) {
        delete draft[collectionKey]!.boards[boardKey];
      }

      return;
    }
  }
}, initialState);
