import { createAction } from 'typesafe-actions';

import { ConnectionStatus } from '../api/firebase.api';

export const subscribePresence = createAction('SUBSCRIBE_PRESENCE', resolve => (boardKey: string) =>
  resolve({ boardKey })
);

export const subscribePresenceError = createAction(
  'SUBSCRIBE_PRESENCE_ERROR',
  resolve => (error: Error) => resolve({ error })
);

export const unsubscribePresence = createAction('UNSUBSCRIBE_PRESENCE');

export const subscribeConnectionStatus = createAction(
  'SUBSCRIBE_CONNECTION_STATUS',
  resolve => (uid: string, boardKey: string) => resolve({ uid, boardKey })
);

export const subscribeConnectionStatusError = createAction(
  'SUBSCRIBE_CONNECTION_STATUS_ERROR',
  resolve => (error: Error) => resolve({ error })
);

export const setStatus = createAction('SET_STATUS', resolve => (status: ConnectionStatus) =>
  resolve({ status })
);

export const pushBoardUsers = createAction(
  'PUSH_BOARD_USERS',
  resolve => (online: Array<string>, offline: Array<string> = []) => resolve({ online, offline })
);

export const setLastActivity = createAction('SET_LAST_ACTIVITY', resolve => (timestamp: number) =>
  resolve({ timestamp })
);

export const setLastActivitySuccess = createAction(
  'SET_LAST_ACTIVITY_SUCCESS',
  resolve => (timestamp: number) => resolve({ timestamp })
);

export const setLastActivityError = createAction(
  'SET_LAST_ACTIVITY_ERROR',
  resolve => (error: Error) => resolve({ error })
);
