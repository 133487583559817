import { select, call, put, take, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import * as api from '../api/firebase.api';
import { IUser } from '../reducers/user.reducer';
import { RootState } from '../config/store';

export default [
  takeEvery(getType(actions.observeAuthentication), observeAuthenticationSaga),
  takeEvery(getType(actions.authenticate), authenticateSaga),
  takeEvery(
    getType(actions.registerUserWithEmailAndPassword),
    registerUserWithEmailAndPasswordSaga,
  ),
  takeEvery(getType(actions.signInAnonymously), signInAnonymouslySaga),
  takeEvery(getType(actions.signInWithEmailAndPassword), signInWithEmailAndPasswordSaga),
  takeEvery(getType(actions.signOut), signOutSaga),
  takeEvery(getType(actions.sendPasswordResetEmail), sendPasswordResetEmailSaga),
  takeEvery(
    [
      getType(actions.signInAnonymouslySuccess),
      getType(actions.signInWithEmailAndPasswordSuccess),
      getType(actions.registerUserWithEmailAndPasswordSuccess),
    ],
    authenticationCompleteSaga,
  ),
  takeEvery(
    [getType(actions.authenticateError), getType(actions.signInAnonymouslyError)],
    handleErrors,
  ),
];

export function* signOutSaga(action: ReturnType<typeof actions.signOut>) {
  try {
    yield put(actions.clearNotificationsSuccess());
    yield call(api.signOut);
    yield put(actions.signOutSuccess());
    yield put(actions.addNotificationSuccess('landingPage', 'success', 'Good bye!', ''));
  } catch (err) {
    yield put(actions.pushError('account', 'signout', err));
  }
}

export function* sendPasswordResetEmailSaga(
  action: ReturnType<typeof actions.sendPasswordResetEmail>,
) {
  try {
    yield put(actions.clearNotificationsSuccess('passwordReset'));
    yield put(actions.setLoadingState('passwordReset', true));
    yield call(api.sendPasswordResetEmail, action.payload.email);
    yield put(actions.sendPasswordResetEmailSuccess(action.payload.email));
    yield put(actions.setLoadingState('passwordReset', false));
    yield put(
      actions.addNotificationSuccess(
        'passwordReset',
        'success',
        'Just a second',
        'An email has been sent to the desired account',
      ),
    );
  } catch (err) {
    yield put(actions.pushError('account', 'passwordReset', err.message));
    yield put(actions.setLoadingState('passwordReset', false));
  }
}

// This function exists for clarity
export function* authenticateSaga(action: ReturnType<typeof actions.authenticate>) {
  try {
    yield put(actions.observeAuthentication());
  } catch (error) {
    yield put(actions.authenticateError(error));
  }
}

export function* signInWithEmailAndPasswordSaga(
  action: ReturnType<typeof actions.signInWithEmailAndPassword>,
) {
  try {
    yield put(actions.clearNotificationsSuccess('login'));
    yield put(actions.setLoadingState('signInWithEmailAndPassword', true));
    const user = yield call(
      api.signInWithEmailAndPassword,
      action.payload.email,
      action.payload.password,
    );
    yield put(actions.signInWithEmailAndPasswordSuccess(user));
    yield put(actions.setLoadingState('signInWithEmailAndPassword', false));
    yield put(actions.addNotificationSuccess('dashboard', 'success', 'Welcome back!', ''));
  } catch (err) {
    yield put(actions.pushError('account', 'login', err.message));
    yield put(actions.setLoadingState('signInWithEmailAndPassword', false));
  }
}

// Will only be called when user is unknown
export function* signInAnonymouslySaga(action: ReturnType<typeof actions.signInAnonymously>) {
  try {
    yield call(api.signInAnonymously);
    // Wait for uid to be set in state
    yield take(getType(actions.selectAuthenticatedUser));
    // Get the uid
    const uid = yield select((state: RootState) => state.ui.authenticatedUser);
    const defaultCollectionKey = yield call(api.generateKey, 'collections');
    yield call(api.createUser, uid, defaultCollectionKey);
  } catch (error) {
    yield put(actions.signInAnonymouslyError(error));
  }
}

export function* registerUserWithEmailAndPasswordSaga(
  action: ReturnType<typeof actions.registerUserWithEmailAndPassword>,
) {
  try {
    yield put(actions.clearNotificationsSuccess('registration'));
    yield put(actions.setLoadingState('registerUserWithEmailAndPassword', true));

    const user: IUser = yield call(
      api.convertAnonymousToPermanentEmailAccount,
      action.payload.email,
      action.payload.password,
    );

    const defaultCollectionKey = yield call(api.generateKey, 'collections');

    yield call(api.createUser, user.uid, defaultCollectionKey);
    yield put(actions.registerUserWithEmailAndPasswordSuccess(user));
    yield put(actions.setLoadingState('registerUserWithEmailAndPassword', false));
    yield put(
      actions.addNotificationSuccess('registration', 'success', "You're done!", 'Enjoy Sendlist.'),
    );
  } catch (error) {
    //  which errors to propagate up into react component, others may be buried into our analytics
    yield put(actions.pushError('account', 'registration', error.message));
    yield put(actions.setLoadingState('registerUserWithEmailAndPassword', false));
  }
}

export function* observeAuthenticationSaga(
  action: ReturnType<typeof actions.observeAuthentication>,
) {
  try {
    const chan = yield call(api.observeAuthentication);

    while (true) {
      const { event, user } = yield take(chan);

      switch (event) {
        case 'unknown_user': {
          // Will redirect to 'anonymous_sign_in' below if successful
          yield put(actions.signInAnonymously());
          break;
        }

        case 'anonymous_sign_in': {
          yield put(actions.signInAnonymouslySuccess(user));
          break;
        }

        case 'email_auth_provider_sign_in': {
          yield put(actions.signInWithEmailAndPasswordSuccess(user));
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    // yield error object to where we handle all the diffeerent errors for this
    // https://firebase.google.com/docs/reference/js/firebase.auth.Error
    console.error(err);
  }
}

export function* authenticationCompleteSaga(
  // different actions here
  action: ReturnType<
    | typeof actions.signInAnonymouslySuccess
    | typeof actions.signInWithEmailAndPasswordSuccess
    | typeof actions.registerUserWithEmailAndPasswordSuccess
  >,
) {
  try {
    const { uid } = action.payload.user;
    yield put(actions.authenticateSuccess(uid));
    // Push firebase user information
    yield put(actions.pushUser(uid, action.payload.user));
    // Subscribe to Sendlist user info
    yield put(actions.subscribeUser(uid));
    yield put(actions.selectAuthenticatedUser(uid));
  } catch (error) {
    yield put(actions.authenticateError(error));
  }
}

export function* handleErrors(action: actions.ErrorAction) {
  yield call(console.error, action.payload.error);
}
