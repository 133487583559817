import { createAction } from 'typesafe-actions';
import { IItem } from '../reducers/item.reducer';

export const addItem = createAction(
  'ADD_ITEM',
  resolve => (
    uid: string,
    listKey: string,
    order: number,
    insertAfterKey: string | undefined,
    persist: boolean,
  ) => resolve({ uid, listKey, insertAfterKey, order, persist }),
);

export const addItemSuccess = createAction(
  'ADD_ITEM_SUCCESS',
  resolve => (listKey: string, itemKey: string) => resolve({ listKey, itemKey }),
);

export const persistItems = createAction(
  'PERSIST_ITEMS',
  resolve => (
    uid: string,
    currentOrderedItemKeys: string[],
    addedItemKeys: Set<string>,
    listKey: string,
  ) =>
    resolve({
      uid,
      currentOrderedItemKeys,
      addedItemKeys,
      listKey,
    }),
);

export const persistItemError = createAction('PERSIST_ITEMS_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const addItemError = createAction('ADD_ITEM_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const updateItem = createAction(
  'UPDATE_ITEM',
  resolve => (
    uid: string,
    listKey: string,
    itemKey: string,
    item: Partial<IItem>,
    persist: boolean = false,
  ) => resolve({ uid, listKey, itemKey, item, persist }),
);

export const updateItemSuccess = createAction(
  'UPDATE_ITEM_SUCCESS',
  resolve => (listKey: string, itemKey: string, item: Partial<IItem>) =>
    resolve({ listKey, itemKey, item }),
);

export const updateItemError = createAction('UPDATE_ITEM_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const deleteItem = createAction(
  'DELETE_ITEM',
  resolve => (listKey: string, itemKey: string, persist: boolean = false) =>
    resolve({ listKey, itemKey, persist }),
);

export const deleteItemSuccess = createAction(
  'DELETE_ITEM_SUCCESS',
  resolve => (listKey: string, itemKey: string) => resolve({ listKey, itemKey }),
);

export const deleteItemError = createAction('DELETE_ITEM_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const pushItem = createAction(
  'PUSH_ITEM',
  resolve => (listKey: string, itemKey: string, item: Partial<IItem>) =>
    resolve({ listKey, itemKey, item }),
);

export const pushItemSuccess = createAction(
  'PUSH_ITEM_SUCCESS',
  resolve => (listKey: string, itemKey: string, item: Partial<IItem>) =>
    resolve({ listKey, itemKey, item }),
);

export const pushItemError = createAction('PUSH_ITEM_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const subscribeItem = createAction(
  'SUBSCRIBE_ITEM',
  resolve => (listKey: string, itemKey: string) => resolve({ listKey, itemKey }),
);

export const subscribeItemError = createAction('SUBSCRIBE_ITEM_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const unsubscribeItem = createAction('UNSUBSCRIBE_ITEM', resolve => (itemKey: string) =>
  resolve({ itemKey }),
);

export const unsubscribeItemError = createAction(
  'UNSUBSCRIBE_ITEM_ERROR',
  resolve => (error: Error) => resolve({ error }),
);
