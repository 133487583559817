import { original, produce } from 'immer';
import { getType } from 'typesafe-actions';
import { Action } from '../actions';
import * as listActions from '../actions/list.actions';
import { ListOfObjectsWithOrderProperty } from '../api/firebase.api';

export interface IList {
  title: string;
  items: ListOfObjectsWithOrderProperty;
  createdAt?: number;
  updatedAt?: number;
  movedAt?: number;
  listStyle: ListStyleTypes;
  createdByUid: string;
  lastUpdateUid: string;
  fresh: boolean;
  dirtyFields: Set<string>;
  dirtyItemsCreated: Set<string>;
  dirtyItemsMoved: Set<string>;
}

export type ListStyleTypes = 'checklist' | 'bullets';

export type ListsState = {
  [key: string]: IList | undefined;
};

export const listFactory = (obj = {}): IList => ({
  title: '',
  items: {},
  fresh: false,
  listStyle: 'checklist',
  createdByUid: '',
  lastUpdateUid: '',
  dirtyFields: new Set([]),
  dirtyItemsCreated: new Set([]),
  dirtyItemsMoved: new Set([]),
  ...obj,
});

export const initialState: ListsState = {};

export const listReducer = produce((draft: ListsState, action: Action) => {
  switch (action.type) {
    case getType(listActions.addListSuccess): {
      const { listKey } = action.payload;
      draft[listKey] = listFactory({ fresh: true });
      return;
    }

    case getType(listActions.updateListSuccess): {
      const { listKey, list } = action.payload;

      draft[listKey] = listFactory({
        ...original(draft[listKey]),
        ...list,
      });

      // Merge newly updated fields with already dirty ones;
      // ignore fields we don't want to be part of this
      draft[listKey]!.dirtyFields = new Set([
        ...draft[listKey]!.dirtyFields,
        ...Object.keys(list).filter(key => {
          return key !== 'dirty' && key !== 'items';
        }),
      ]);

      return;
    }

    case getType(listActions.pushListSuccess): {
      const { listKey, list } = action.payload;

      draft[listKey] = listFactory({
        ...original(draft[listKey]),
        ...list,
      });

      const dirtyFields = draft[listKey] && draft[listKey]!.dirtyFields;

      // Remove pushed fields from dirty. We do not check for field equality
      // because we assume the server sent the latest version
      if (dirtyFields && dirtyFields.size > 0) {
        // Get the pushed keys
        Object.keys(list).map(key => {
          // Check if they are considered dirty
          if (dirtyFields.has(key)) {
            dirtyFields.delete(key);
            draft[listKey]!.dirtyFields = dirtyFields;
          }
          return null;
        });
      }

      return;
    }

    case getType(listActions.deleteListSuccess): {
      const { listKey } = action.payload;
      delete draft[listKey];
      return;
    }

    case getType(listActions.addItemToListSuccess): {
      const { listKey, itemKey, order } = action.payload;

      const list = draft[listKey];

      if (list) {
        list.items[itemKey] = { order };
        list.dirtyItemsCreated.add(itemKey);
      }

      return;
    }

    case getType(listActions.pushItemToListSuccess): {
      const { listKey, itemKey, order } = action.payload;

      if (draft[listKey]) {
        draft[listKey]!.items[itemKey] = { order };
        // Delete from both Sets handling dirty Items because
        // we can assume items that are newly created won't be
        // added to dirtyItemsMoved, instead they should be
        // created with the correct order
        draft[listKey]!.dirtyItemsCreated.delete(itemKey);
        draft[listKey]!.dirtyItemsMoved.delete(itemKey);
      }

      return;
    }

    case getType(listActions.deleteItemFromListSuccess): {
      const { listKey, itemKey } = action.payload;

      if (draft[listKey] && draft[listKey]!.items[itemKey]) {
        delete draft[listKey]!.items[itemKey];
        draft[listKey]!.dirtyItemsCreated.delete(itemKey);
        draft[listKey]!.dirtyItemsMoved.delete(itemKey);
      }

      return;
    }

    // Please note that we currently do not handle dirtyItemsMoved
    // as we only care about newly created items for now
    case getType(listActions.moveListItemSuccess): {
      const { listKey, itemKey, order, targetListKey } = action.payload;

      const sourceList = draft[listKey];

      if (sourceList) {
        // Must itemKey be present in source list
        if (sourceList.items[itemKey]) {
          // Moving between two lists
          if (targetListKey && listKey !== targetListKey) {
            // Remove item from original list, if present
            delete sourceList.items[itemKey];

            const targetList = draft[targetListKey];
            if (targetList) {
              targetList.items[itemKey] = { order };
              if (sourceList.dirtyItemsCreated.has(itemKey)) {
                targetList.dirtyItemsCreated.add(itemKey);
                sourceList.dirtyItemsCreated.delete(itemKey);
              } else if (sourceList.dirtyItemsMoved.has(itemKey)) {
                targetList.dirtyItemsMoved.add(itemKey);
                sourceList.dirtyItemsMoved.delete(itemKey);
              }
            }
          } else {
            sourceList.items[itemKey] = { order };

            // Prevent from adding to dirtyItemsMoved when
            // it is dirty in dirtyItemsCreated
            if (!sourceList.dirtyItemsCreated.has(itemKey)) {
              sourceList.dirtyItemsMoved.add(itemKey);
            }
          }
        }
      }

      return;
    }
  }
}, initialState);
