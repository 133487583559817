import { takeEvery, takeLatest, fork, put, call, take, cancel } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';

import * as api from '../api/firebase.api';
import { subscribeItemError } from '../actions';

export default [
  takeEvery(getType(actions.addItem), addItemSaga),
  takeEvery(getType(actions.deleteItem), deleteItemSaga),
  takeEvery(getType(actions.updateItem), updateItemSaga),
  takeLatest(getType(actions.pushItem), pushItemSaga),
  takeEvery(getType(actions.subscribeItem), subscribeItemSaga),
  takeEvery(getType(actions.persistItems), persistItemsSaga),
  takeEvery(
    [
      getType(actions.addItemError),
      getType(actions.deleteItemError),
      getType(actions.pushItemError),
      getType(actions.subscribeItemError),
      getType(actions.updateItemError),
      getType(actions.persistItemError),
    ],
    itemErrorsSaga,
  ),
];

export function* subscribeItemSaga(action: ReturnType<typeof actions.subscribeItem>) {
  try {
    const task = yield fork(subscribeItemTask, action.payload.listKey, action.payload.itemKey);

    while (true) {
      const unsubscribedItem = yield take(getType(actions.unsubscribeItem));

      if (action.payload.itemKey === unsubscribedItem.itemKey) {
        yield cancel(task);
        yield put(actions.deleteItem(action.payload.listKey, action.payload.itemKey, false));
        break;
      }
    }
  } catch (err) {
    yield put(actions.subscribeItemError(err));
  }
}

export function* subscribeItemTask(listKey: string, itemKey: string) {
  const chan = yield call(api.subscribeObject, 'items', itemKey, 'data');

  try {
    while (true) {
      const { event, key, values }: api.FirebaseEvent<api.ItemDataServerNode> = yield take(chan);

      switch (event) {
        case 'value': {
          if (api.isDataObject(key, values)) {
            yield put(actions.pushItem(listKey, itemKey, values));
          } else {
            yield put(
              actions.subscribeItemError(new Error('Key returned from server is not in use')),
            );
          }
          break;
        }

        case 'child_changed': {
          if (key && api.isChildNode(key, values)) {
            yield put(actions.pushItem(listKey, itemKey, { [key]: values }));
          }
          break;
        }

        case 'child_added': {
          if (key && api.isChildNode(key, values)) {
            yield put(actions.pushItem(listKey, itemKey, { [key]: values }));
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(subscribeItemError(err));
  } finally {
    // Upon cancelling task via UNSUBSCRIBE_ITEM, detach listener
    yield chan.close();
  }
}

export function* addItemSaga(action: ReturnType<typeof actions.addItem>) {
  try {
    const { listKey, uid, order, insertAfterKey, persist } = action.payload;

    const itemKey = yield call(api.generateKey, 'items');

    if (typeof itemKey !== 'string') {
      throw new Error('Could not generate valid itemKey');
    }

    yield put(actions.addItemSuccess(listKey, itemKey));
    yield put(actions.addItemToList(listKey, itemKey, order));

    if (persist) {
      yield call(persistItemRaceSaga, uid, listKey, itemKey, insertAfterKey);
    }
  } catch (err) {
    yield put(actions.addItemError(err));
  }
}

export function* persistItemsSaga(action: ReturnType<typeof actions.persistItems>) {
  try {
    const { uid, listKey, currentOrderedItemKeys, addedItemKeys } = action.payload;

    // Iterate over current itemKeys which include addedItemKeys and determine
    // value of insertAfterKey if possible; will only work with a `for` loop and only `for ... of`
    // guarantees order in an array that we require in this case
    for (const itemKey of currentOrderedItemKeys) {
      if (addedItemKeys.has(itemKey)) {
        // Determine the insertAfterKey
        const prevItemKeyIndex = currentOrderedItemKeys.indexOf(itemKey) - 1;
        const prevItemKey =
          prevItemKeyIndex >= 0 ? currentOrderedItemKeys[prevItemKeyIndex] : undefined;

        yield call(persistItemRaceSaga, uid, listKey, itemKey, prevItemKey);
      }
    }
    // });
  } catch (e) {
    yield put(actions.persistItemError(e));
  }
}

export function* persistItemRaceSaga(
  uid: string,
  listKey: string,
  itemKey: string,
  insertAfterKey?: string,
) {
  const task = yield fork(persistItemTask, uid, listKey, itemKey, insertAfterKey);

  // There is a race effect in redux saga, but I'm not sure how I can verify
  // the keys there, so this is my solution
  while (true) {
    // We await either of these events, where pushItem indicates a server event
    const race: ReturnType<typeof actions.pushItem | typeof actions.deleteItem> = yield take([
      getType(actions.pushItem),
      getType(actions.deleteItem),
    ]);

    // Verify it's the same item
    if (race.payload.itemKey === itemKey) {
      if (race.type === getType(actions.pushItem)) {
        // Item has been pushed from server; end
        break;
      }

      if (race.type === getType(actions.deleteItem)) {
        // Item has been deleted; cancel task and end
        yield cancel(task);
        break;
      }
    }
  }
}

export function* persistItemTask(
  uid: string,
  listKey: string,
  itemKey: string,
  insertAfterKey?: string,
) {
  yield call(
    api.createObjectInOrderedCollection,
    uid,
    'lists',
    listKey,
    'items',
    itemKey,
    insertAfterKey,
    {
      [`items/${itemKey}/listKey`]: listKey,
    },
  );
}

export function* updateItemSaga(action: ReturnType<typeof actions.updateItem>) {
  try {
    const { listKey, itemKey, item, uid, persist } = action.payload;

    yield put(actions.updateItemSuccess(listKey, itemKey, item));

    if (persist) {
      yield call(api.updateObject, 'items', uid, itemKey, item);
    }
  } catch (err) {
    yield put(actions.updateItemError(err));
  }
}

export function* deleteItemSaga(action: ReturnType<typeof actions.deleteItem>) {
  try {
    const { listKey, itemKey, persist } = action.payload;
    yield put(actions.deleteItemSuccess(listKey, itemKey));
    yield put(actions.deleteItemFromList(listKey, itemKey));

    if (persist) {
      yield call(api.deleteObject, 'items', itemKey, {
        [`lists/${listKey}/items/${itemKey}`]: null,
        [`/items/${itemKey}/listKey`]: null,
      });
    }
  } catch (err) {
    yield put(actions.deleteItemError(err));
  }
}

export function* pushItemSaga(action: ReturnType<typeof actions.pushItem>) {
  try {
    const { itemKey, listKey, item } = action.payload;
    yield put(actions.pushItemSuccess(listKey, itemKey, item));
  } catch (err) {
    yield put(actions.pushItemError(err));
  }
}

export function* itemErrorsSaga(action: actions.ErrorAction) {
  yield call(console.error, action.payload.error);
}
