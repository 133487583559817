import { createAction } from 'typesafe-actions';
import { IBoardExpiry } from 'reducers/boardExpiry.reducer';
import { IBoard } from 'reducers/board.reducer';

export const subscribeBoardExpiry = createAction(
  'SUBSCRIBE_BOARD_EXPIRY',
  resolve => (boardKey: string) => resolve({ boardKey }),
);

export const subscribeBoardExpiryError = createAction(
  'SUBSCRIBE_BOARD_EXPIRY_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const addBoardExpiry = createAction(
  'ADD_BOARD_EXPIRY',
  resolve => (boardKey: string, uid: string, expiresAt: number) =>
    resolve({
      boardKey,
      uid,
      expiresAt,
    }),
);

export const addBoardExpirySuccess = createAction(
  'ADD_BOARD_EXPIRY_SUCCESS',
  resolve => (boardKey: string, uid: string, expiresAt: number) =>
    resolve({
      boardKey,
      uid,
      expiresAt,
    }),
);

export const addBoardExpiryError = createAction(
  'ADD_BOARD_EXPIRY_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const updateBoardExpiry = createAction(
  'UPDATE_BOARD_EXPIRY',
  resolve => (boardKey: string, uid: string, expiresAt: number) =>
    resolve({
      boardKey,
      uid,
      expiresAt,
    }),
);

export const updateBoardExpirySuccess = createAction(
  'UPDATE_BOARD_EXPIRY_SUCCESS',
  resolve => (boardKey: string, uid: string, expiresAt: number) =>
    resolve({
      boardKey,
      uid,
      expiresAt,
    }),
);

// We don't update the state locally for now
export const updateBoardExpiryError = createAction(
  'UPDATE_BOARD_EXPIRY_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const deleteBoardExpiry = createAction(
  'DELETE_BOARD_EXPIRY',
  resolve => (boardKey: string) => resolve({ boardKey }),
);

export const deleteBoardExpirySuccess = createAction(
  'DELETE_BOARD_EXPIRY_SUCCESS',
  resolve => (boardKey: string) => resolve({ boardKey }),
);

export const deleteBoardExpiryError = createAction(
  'DELETE_BOARD_EXPIRY_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushBoardExpiry = createAction(
  'PUSH_BOARD_EXPIRY',
  resolve => (boardKey: string, boardExpiry: Partial<IBoardExpiry>) =>
    resolve({
      boardKey,
      boardExpiry,
    }),
);

export const pushBoardExpirySuccess = createAction(
  'PUSH_BOARD_EXPIRY_SUCCESS',
  resolve => (boardKey: string, boardExpiry: Partial<IBoardExpiry>) =>
    resolve({
      boardKey,
      boardExpiry,
    }),
);

export const pushBoardExpiryError = createAction(
  'PUSH_BOARD_EXPIRY_ERROR',
  resolve => (error: Error) => resolve({ error }),
);
