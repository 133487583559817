import * as React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import store from './config/store';
import { firstTheme } from './styles/themes/first';

if (process.env.NODE_ENV !== 'production') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  // whyDidYouRender(React, { include: [/^ConnectFunction/] });
  whyDidYouRender(React, {});
}

const MainContainer = React.lazy(() => import('./containers/Main'));

const App = () => (
  <React.Suspense fallback={<div>Loading</div>}>
    <Provider store={store}>
      <ThemeProvider theme={firstTheme}>
        <BrowserRouter>
          <MainContainer />
        </BrowserRouter>
      </ThemeProvider>
    </Provider>
  </React.Suspense>
);

export default App;
