import * as _ from 'lodash';

import { ServerResponse, OrderedRelation } from './firebase.api';

export const convertRelationToArrayAndSortByOrder = (
  data: OrderedRelation | boolean,
): Array<string> => {
  // We accept boolean as a valid argument because Firebase may provide us with that; just being
  // cautious here
  if (_.isObject(data)) {
    const pairs = _.toPairs(data);
    const sortedByOrder = _.sortBy(pairs, p => _.get(p, ['1', 'order']));
    return sortedByOrder.map(el => _.get(el, ['0']));
  }

  return _.identity([]);
};

export const reverseArray = _.reverse;

// When firebase returns 'true' on empty relations, send empty object
export const normalizeResponse = (data: ServerResponse | boolean): ServerResponse | {} => {
  if (_.isBoolean(data)) {
    return _.identity({});
  }

  return _.identity(data);
};

export const generatePairs = (list: Array<string>): Array<[string, number]> =>
  // @ts-ignore: code is fine and okay as of slow TS version 3.4
  list.map((el, index) => [el, index]);

export const convertArrayToOrderedRelation = (
  prependString: string,
  list: Array<string>,
): { [key: string]: number } => {
  const pairs = generatePairs(list);

  // @ts-ignore: code is fine and okay as of slow TS version 3.4
  const pairsWithOrderProp: Array<[string, number]> = pairs.map((el, index) => {
    return [`${prependString}${_.head(el)}/order`, index];
  });

  return _.fromPairs(pairsWithOrderProp);
};

// Deprecated: Maymove into cloud function
// Recursively creates pairs
// export const generateDeepPairs = (data: Object) => {
//   const reducer = (acc, val) => {
//     const getPair = R.map(el =>
//       R.ifElse(
//         R.is(Object),
//         R.pipe(
//           R.toPairs,
//           R.reduce(reducer, []),
//         ),
//         R.always(el),
//       )(el),
//     );

//     return R.append(getPair(val), acc);
//   };

//   return R.when(
//     R.is(Object),
//     R.pipe(
//       R.toPairs,
//       R.reduce(reducer, []),
//     ),
//   )(data);
// };

// Deprecated: Maymove into cloud function
// A probably over simplistic reduce function that goes into the deep and executes function
// export const recursiveReduceAndCallFunction = R.curry(
//   (fn: Function, initialValue: Array<any>, payload: Array<Array<any>>) => {
//     // Recursive function
//     const reducer = (acc, val) => {
//       const map = R.ifElse(R.is(Array), R.reduce(reducer, []), R.call(fn));

//       return R.append(map(val), acc);
//     };

//     return R.map(
//       el => R.ifElse(R.is(Array), R.reduce(reducer, initialValue), R.always(fn(el)))(el),
//       payload,
//     );
//   },
// );

// Deprecated: May move into cloud function
// Recreates object from pairs recursively
// export const deepFromPairs = (data: Deep<string | number>) => {
//   const map = R.map(el =>
//     R.when(
//       R.is(Array),
//       R.pipe(
//         R.map(map),
//         R.fromPairs,
//       ),
//     )(el),
//   );

//   return R.when(
//     R.is(Array),
//     R.pipe(
//       R.map(map),
//       R.fromPairs,
//     ),
//   )(data);
// };

// Deprecated: Maymove into cloud function
// Found somewhere on Stackoverflow; no ramda solution in sight, so some _ here
// Finds difference between two objects and returns the diff as an object
// export const objDiff = R.curry((lhs: Object, rhs: Object) => {
//   const changes = (lhs: { [key: string]: any }, rhs: { [key: string]: any }) => {
//     return _.transform(lhs, function(result, value, key) {
//       if (!_.isEqual(value, rhs[key])) {
//         result[key] = _.isObject(value) && _.isObject(rhs[key]) ? changes(value, rhs[key]) : value;
//       }
//     });
//   };
//   return changes(rhs, lhs);
// });

// Deprecated, but may find its way into cloud function
// Replaces uids by turning object into a paired array and then back to object
// export const replaceKeyOrValueAndReturnDiffObj = R.curry(
//   (oldValue: string, newValue: string, payload: Object) =>
//     R.pipe(
//       generateDeepPairs,
//       recursiveReduceAndCallFunction(
//         R.pipe(R.when(R.is(String), R.replace(oldValue, newValue))),
//         [],
//       ),
//       deepFromPairs,
//       // $FlowFixMe : Seems about right for me, maybe currying is causing this error?
//       R.call(objDiff, payload),
//     )(payload),
// );

// Deprecated: May move into cloud function
// Via: https://stackoverflow.com/questions/19098797/fastest-way-to-flatten-un-flatten-nested-json-objects
// export const flattenObject = (prefix: string, data: Object) => {
//   var result: { [key: string]: any } = {};
//   function recurse(cur: { [key: string]: any }, prop: string) {
//     if (Object(cur) !== cur) {
//       result[prop] = cur;
//     } else if (Array.isArray(cur)) {
//       for (var i = 0, l = cur.length; i < l; i++) recurse(cur[i], prop + '[' + i + ']');
//       if (l === 0) result[prop] = [];
//     } else {
//       var isEmpty = true;
//       for (var p in cur) {
//         isEmpty = false;
//         recurse(cur[p], prop ? prop + '/' + p : p);
//       }
//       if (isEmpty && prop) result[prop] = {};
//     }
//   }
//   recurse(data, prefix);
//   return result;
// };

// Deprecated: Maymove into cloud function
// export const mergeObjects = (lhs: Object, rhs: Object) => R.merge(lhs, rhs);

// Deprecated: Maymove into cloud function
// export const flattenArray = (arr: Array<any>) => R.flatten(arr);
