import { createAction } from 'typesafe-actions';

export const pushError = createAction(
  'PUSH_ERROR',
  resolve => (container: string, field: string, message: string) =>
    resolve({ container, field, message })
);

export const clearError = createAction(
  'CLEAR_ERROR',
  resolve => (container: string, field: string = '') => resolve({ container, field })
);

export const clearAllErrors = createAction('CLEAR_ALL_ERRORS');
