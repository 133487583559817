import { original, produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';

export interface IUserBoard {
  role: string;
  collection: string;
  starred: { order: number };
  expiresAt?: number;
  boardCreatedByThisUserAt?: number;
  boardAddedByThisUserAt?: number;
}

export type UserBoardsState = {
  [key: string]: IUserBoard;
};

export const userBoardFactory = (obj = {}): IUserBoard => ({
  role: '',
  collection: '',
  starred: { order: 0 },
  ...obj,
});

export const initialState: UserBoardsState = {};

export const userBoardReducer = produce((draft: UserBoardsState, action: actions.Action) => {
  switch (action.type) {
    case getType(actions.pushUserBoardsSuccess): {
      const { boards } = action.payload;

      Object.assign(draft, {
        ...original(draft),
        ...boards,
      });

      return;
    }

    case getType(actions.addBoardToUserBoardsSuccess):
    case getType(actions.updateUserBoardSuccess):
    case getType(actions.pushBoardToUserBoardsSuccess): {
      const { userBoard, boardKey } = action.payload;

      // Because subscribe at /userBoards/$uid userBoard
      // will always contain the current information of the node,
      // so no need to merge with previous data
      draft[boardKey] = {
        ...userBoardFactory({
          ...userBoard,
        }),
      };

      return;
    }

    case getType(actions.moveUserBoardSuccess): {
      const { boardKey, order } = action.payload;
      const userBoard = draft[boardKey];

      if (userBoard) {
        userBoard.starred = { order };
      }
      return;
    }

    case getType(actions.deleteBoardFromUserBoardsSuccess): {
      const { boardKey } = action.payload;

      if (draft[boardKey]) {
        delete draft[boardKey];
      }

      return;
    }
  }
}, initialState);
