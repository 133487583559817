import { createAction } from 'typesafe-actions';

import { ICollection } from '../reducers/collection.reducer';
import { ListOfObjectsWithOrderProperty } from '../api/firebase.api';

export const subscribeCollectionBoards = createAction(
  'SUBSCRIBE_COLLECTION_BOARDS',
  resolve => (collectionKey: string, limit: number = 10, childValue?: number, childKey?: string) =>
    resolve({ collectionKey, limit, childValue, childKey }),
);

export const subscribeCollectionBoardsError = createAction(
  'SUBSCRIBE_COLLECTION_BOARDS_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushCollection = createAction(
  'PUSH_COLLECTION',
  resolve => (collectionKey: string, collection: Partial<ICollection>) =>
    resolve({ collectionKey, collection }),
);

export const pushCollectionSuccess = createAction(
  'PUSH_COLLECTION_SUCCESS',
  resolve => (collectionKey: string, collection: Partial<ICollection>) =>
    resolve({ collectionKey, collection }),
);

export const pushCollectionError = createAction(
  'PUSH_COLLECTION_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const subscribeCollection = createAction(
  'SUBSCRIBE_COLLECTION',
  resolve => (collectionKey: string) => resolve({ collectionKey }),
);

export const subscribeCollectionError = createAction(
  'SUBSCRIBE_COLLECTION_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushCollectionBoards = createAction(
  'PUSH_COLLECTION_BOARDS',
  resolve => (collectionKey: string, boardKeys: ListOfObjectsWithOrderProperty) =>
    resolve({ collectionKey, boardKeys }),
);

export const pushCollectionBoardsSuccess = createAction(
  'PUSH_COLLECTION_BOARDS_SUCCESS',
  resolve => (collectionKey: string, boardKeys: ListOfObjectsWithOrderProperty) =>
    resolve({ collectionKey, boardKeys }),
);

export const pushCollectionBoardsError = createAction(
  'PUSH_COLLECTION_BOARDS_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const addBoardToCollection = createAction(
  'ADD_BOARD_TO_COLLECTION',
  resolve => (collectionKey: string, boardKey: string, order: number) =>
    resolve({ collectionKey, boardKey, order }),
);

export const addBoardToCollectionSuccess = createAction(
  'ADD_BOARD_TO_COLLECTION_SUCCESS',
  resolve => (collectionKey: string, boardKey: string, order: number) =>
    resolve({ collectionKey, boardKey, order }),
);

export const addBoardToCollectionError = createAction(
  'ADD_BOARD_TO_COLLECTION_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushBoardToCollection = createAction(
  'PUSH_BOARD_TO_COLLECTION',
  resolve => (collectionKey: string, boardKey: string, order: number) =>
    resolve({ collectionKey, boardKey, order }),
);

export const pushBoardToCollectionSuccess = createAction(
  'PUSH_BOARD_TO_COLLECTION_SUCCESS',
  resolve => (collectionKey: string, boardKey: string, order: number) =>
    resolve({ collectionKey, boardKey, order }),
);

export const pushBoardToCollectionError = createAction(
  'PUSH_BOARD_TO_COLLECTION_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const moveCollectionBoard = createAction(
  'MOVE_COLLECTION_BOARD',
  resolve => (
    uid: string,
    collectionKey: string,
    boardKey: string,
    order: number,
    insertAfterKey: string | undefined,
    persist: boolean = false,
  ) => resolve({ uid, collectionKey, boardKey, order, insertAfterKey, persist }),
);

export const moveCollectionBoardSuccess = createAction(
  'MOVE_COLLECTION_BOARD_SUCCESS',
  resolve => (collectionKey: string, boardKey: string, order: number) =>
    resolve({ collectionKey, boardKey, order }),
);

export const moveCollectionBoardError = createAction(
  'MOVE_COLLECTION_BOARD_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const deleteBoardFromCollection = createAction(
  'DELETE_BOARD_FROM_COLLECTION',
  resolve => (collectionKey: string, boardKey: string) => resolve({ collectionKey, boardKey }),
);

export const deleteBoardFromCollectionSuccess = createAction(
  'DELETE_BOARD_FROM_COLLECTION_SUCCESS',
  resolve => (collectionKey: string, boardKey: string) => resolve({ collectionKey, boardKey }),
);

export const deleteBoardFromCollectionError = createAction(
  'DELETE_BOARD_FROM_COLLECTION_ERROR',
  resolve => (error: Error) => resolve({ error }),
);
