import { ActionType } from 'typesafe-actions';

import * as authenticationActions from './authentication.actions';
import * as boardActions from './board.actions';
import * as boardExpiryActions from './boardExpiry.actions';
import * as collectionActions from './collection.actions';
import * as errorActions from './error.actions';
import * as itemActions from './item.actions';
import * as listActions from './list.actions';
import * as notificationActions from './notification.actions';
import * as presenceActions from './presence.actions';
import * as uiActions from './ui.actions';
import * as userActions from './user.actions';
import * as userBoardsActions from './userBoards.actions';

export * from './board.actions';
export * from './boardExpiry.actions';
export * from './collection.actions';
export * from './list.actions';
export * from './item.actions';
export * from './user.actions';
export * from './userBoards.actions';
export * from './ui.actions';
export * from './presence.actions';
export * from './authentication.actions';
export * from './error.actions';
export * from './notification.actions';

export type Action = ActionType<
  | typeof authenticationActions
  | typeof boardActions
  | typeof boardExpiryActions
  | typeof collectionActions
  | typeof errorActions
  | typeof listActions
  | typeof itemActions
  | typeof notificationActions
  | typeof presenceActions
  | typeof uiActions
  | typeof userActions
  | typeof userBoardsActions
>;

export type ErrorAction = {
  type: string;
  payload: {
    error: Error;
  };
};
