import { takeEvery, put, call, take } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import firebase from '../config/firebase.config';

import * as actions from '../actions';

import * as api from '../api/firebase.api';
import { UserBoardsSubscriptionResponse } from '../api/firebase.api';
import { UserBoardsState, IUserBoard } from 'reducers/userBoards.reducer';

export default [
  takeEvery(getType(actions.pushUserBoards), pushUserBoardsSaga),
  takeEvery(getType(actions.subscribeUserBoards), subscribeUserBoardsSaga),
  takeEvery(getType(actions.pushBoardToUserBoards), pushBoardToUserBoardsSaga),
  takeEvery(getType(actions.addBoardToUserBoards), addBoardToUserBoardsSaga),
  takeEvery(getType(actions.deleteBoardFromUserBoards), deleteBoardFromUserBoardsSaga),
  takeEvery(getType(actions.updateUserBoard), updateUserBoardSaga),
  takeEvery(getType(actions.moveUserBoard), moveUserBoardSaga),
  takeEvery(
    [
      getType(actions.pushUserBoardsError),
      getType(actions.subscribeUserBoardsError),
      getType(actions.pushBoardToUserBoardsError),
      getType(actions.addBoardToUserBoardsError),
      getType(actions.deleteBoardFromUserBoardsError),
      getType(actions.updateUserBoardError),
      getType(actions.moveUserBoardError),
    ],
    handleErrors,
  ),
];

export function* moveUserBoardSaga(action: ReturnType<typeof actions.moveUserBoard>) {
  try {
    const { uid, filter, boardKey, order, persist, insertAfterKey } = action.payload;

    yield put(actions.moveUserBoardSuccess(filter, boardKey, order, insertAfterKey, persist));

    if (persist) {
      // We are somewhat simplifying the reordering of userBoards because
      // the different structure would require major changes for a feature
      // that is currently tiny (the reordering of starred userboards). Therefore,
      // we evaluate the next order locally and simply update the remote prop
      // with that information. This is not a huge issue because there is a much
      // lower risk vs. the real time changes of multiple users. This only
      // affects individual users.
      yield firebase
        .database()
        .ref(`/userBoards/${uid}/${boardKey}/${filter}/`)
        .update({ order });
    }
  } catch (error) {
    yield put(actions.moveUserBoardError(error));
  }
}

export function* deleteBoardFromUserBoardsSaga(
  action: ReturnType<typeof actions.deleteBoardFromUserBoards>,
) {
  const { boardKey } = action.payload;

  try {
    yield put(actions.deleteBoardFromUserBoardsSuccess(boardKey));
  } catch (error) {
    yield put(actions.deleteBoardFromUserBoardsError(error));
  }
}

export function* addBoardToUserBoardsSaga(action: ReturnType<typeof actions.addBoardToUserBoards>) {
  const { boardKey, userBoard } = action.payload;

  try {
    yield put(actions.addBoardToUserBoardsSuccess(boardKey, userBoard));
  } catch (error) {
    yield put(actions.addBoardToUserBoardsError(error));
  }
}

export function* updateUserBoardSaga(action: ReturnType<typeof actions.updateUserBoard>) {
  const { boardKey, userBoard, uid } = action.payload;

  try {
    yield put(actions.updateUserBoardSuccess(boardKey, userBoard));
    // yield call(api.updateObject, 'userBoards', uid, boardKey, userBoard);
  } catch (error) {
    yield put(actions.updateUserBoardError(error));
  }
}

export function* pushUserBoardsSaga(action: ReturnType<typeof actions.pushUserBoards>) {
  const { boards } = action.payload;

  try {
    yield put(actions.pushUserBoardsSuccess(boards));
  } catch (error) {
    yield put(actions.pushCollectionBoardsError(error));
  }
}

export function* pushBoardToUserBoardsSaga(
  action: ReturnType<typeof actions.pushBoardToUserBoards>,
) {
  const { boardKey, userBoard } = action.payload;

  try {
    yield put(actions.pushBoardToUserBoardsSuccess(boardKey, userBoard));
  } catch (error) {
    yield put(actions.pushCollectionBoardsError(error));
  }
}

export function* subscribeUserBoardsSaga(action: ReturnType<typeof actions.subscribeUserBoards>) {
  const { uid, limit, sort, filterBy, childKey, filterValue } = action.payload;

  const chan = yield call(api.subscribeObject, 'userBoards', uid, undefined, {
    enableOverride: true,
    sort,
    childValue: 0,
    childKey,
    orderBy: filterBy,
  });

  try {
    while (true) {
      const { key, event, values }: UserBoardsSubscriptionResponse = yield take(chan);

      switch (event) {
        case 'value': {
          if (api.isObject(values)) {
            yield put(actions.pushUserBoards(values as UserBoardsState));
          }
          break;
        }

        case 'child_added': {
          if (key && values) {
            yield put(actions.pushBoardToUserBoards(key, values as IUserBoard));
          } else {
            throw new Error('Values returned by server are null or not processed');
          }
          break;
        }

        case 'child_changed': {
          if (key && values) {
            yield put(actions.pushBoardToUserBoards(key, values as IUserBoard));
          } else {
            throw new Error('Values returned by server are null or not processed');
          }
          break;
        }

        case 'child_removed': {
          if (key) {
            // yield put(actions.deleteBoardFromCollection(filterBy, key));
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.subscribeUserBoardsError(err));
  } finally {
    chan.off();
  }
}

export function* handleErrors(error: actions.ErrorAction) {
  yield call(console.error, error);
}
