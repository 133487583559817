/**
 * Queries.ts — Where all queries live
 * NOTE: We consider these queries to be extensively tested as they serve
 * as the basis for testing security rules.
 */

/**
 * Boards
 */

export type roles = 'owners' | 'admins' | 'authors' | 'subscribers';

// CREATE
export const generateValidCreateBoardQuery = (
  uidArg: string,
  roleArg: roles,
  collectionKeyArg: string,
  boardKeyArg: string,
  order: number,
  onlyAdding: boolean = false,
) => ({
  [`/boardUsers/${boardKeyArg}/${uidArg}/role`]: roleArg,
  [`/collections/${collectionKeyArg}/boards/${boardKeyArg}/order`]: order,
  [`/boards/${boardKeyArg}/data/createdByUid`]: uidArg,
  [`/permissions/boards/${boardKeyArg}/${roleArg}/${uidArg}`]: true,
  [`/boards/${boardKeyArg}/data/createdAt`]: {
    '.sv': 'timestamp',
  },
  [`/userBoards/${uidArg}/${boardKeyArg}/collection`]: collectionKeyArg,
  [`/userBoards/${uidArg}/${boardKeyArg}/role`]: roleArg,
  [onlyAdding
    ? `/userBoards/${uidArg}/${boardKeyArg}/boardAddedByThisUserAt`
    : `/userBoards/${uidArg}/${boardKeyArg}/boardCreatedByThisUserAt`]: { '.sv': 'timestamp' },
});

export const generateValidMoveBoardToCollectionQuery = (
  uid: string,
  prevCollectionKeyArg: string,
  nextCollectionKeyArg: string,
  boardKeyArg: string,
  nextOrder: number,
) => ({
  [`/userBoards/${uid}/${boardKeyArg}/collection`]: nextCollectionKeyArg,
  [`/collections/${prevCollectionKeyArg}/boards/${boardKeyArg}`]: null,
  [`/collections/${nextCollectionKeyArg}/boards/${boardKeyArg}/order`]: nextOrder,
});

// ADD AUTHOR
// This is merely for convenience
export const generateValidAddAuthorToBoardQuery = (
  boardKey: string,
  roleUid: string,
  collectionKey: string,
) => generateAddUserToBoardQuery(boardKey, roleUid, 'authors', collectionKey);

export const generateAddUserToBoardQuery = (
  boardKey: string,
  uid: string,
  role: roles,
  collectionKey: string,
  order: number = 0,
) => ({
  [`/permissions/boards/${boardKey}/${role}/${uid}`]: true,
  [`/boardUsers/${boardKey}/${uid}/role`]: role,
  [`/collections/${collectionKey}/boards/${boardKey}/order`]: order,
  [`/userBoards/${uid}/${boardKey}/collection`]: collectionKey,
  [`/userBoards/${uid}/${boardKey}/role`]: role,
  [`/userBoards/${uid}/${boardKey}/boardAddedByThisUserAt`]: { '.sv': 'timestamp' },
});

// CHANGE ROLE
export const generateChangeBoardRoleQuery = (
  uid: string,
  boardKey: string,
  prevRole: roles,
  nextRole: roles,
) => ({
  [`/permissions/boards/${boardKey}/${nextRole}/${uid}`]: true,
  [`/permissions/boards/${boardKey}/${prevRole}/${uid}`]: null,
  [`/userBoards/${uid}/${boardKey}/role`]: nextRole,
  [`/boardUsers/${boardKey}/${uid}/role`]: nextRole,
});

// DISCONNECT USER FROM BOARD
export const generateDisconnnectUserFromBoardQuery = (
  uid: string,
  boardKey: string,
  role: roles,
  collectionKey: string,
) => ({
  [`/userBoards/${uid}/${boardKey}/expiresAt`]: null,
  [`/userBoards/${uid}/${boardKey}/boardCreatedByThisUserAt`]: null,
  [`/userBoards/${uid}/${boardKey}/boardAddedByThisUserAt`]: null,
  [`/userBoards/${uid}/${boardKey}/starred`]: null,
  [`/userBoards/${uid}/${boardKey}/role`]: null,
  [`/userBoards/${uid}/${boardKey}/collection`]: null,
  [`/boardUsers/${boardKey}/${uid}`]: null,
  [`/permissions/boards/${boardKey}/${role}/${uid}`]: null,
  [`/collections/${collectionKey}/boards/${boardKey}`]: null,
});

/**
 * ITEMS
 */

// CREATE
export const generateValidCreateItemQuery = (
  uid: string,
  listKey: string,
  itemKey: string,
  order: number,
) => ({
  [`/items/${itemKey}/listKey`]: listKey,
  [`/lists/${listKey}/items/${itemKey}/order`]: order,
  [`/items/${itemKey}/data/createdByUid`]: uid,
  [`/items/${itemKey}/data/createdAt`]: {
    '.sv': 'timestamp',
  },
});

// DELETE
export const generateValidDeleteItemQuery = (listKey: string, itemKey: string) => ({
  [`/lists/${listKey}/items/${itemKey}`]: null,
  [`/items/${itemKey}`]: null,
});

// MOVING
export const generateValidMoveItemQuery = (
  sourceListKey: string,
  targetListKey: string,
  itemKey: string,
  order: number,
) => ({
  [`/items/${itemKey}/listKey`]: targetListKey,
  [`/lists/${sourceListKey}/items/${itemKey}`]: null,
  [`/lists/${targetListKey}/items/${itemKey}/order`]: order,
});

/**
 * LISTS
 */

// CREATE
export const generateValidCreateListQuery = (
  uid: string,
  listKey: string,
  boardKey: string,
  order: number,
) => ({
  [`/lists/${listKey}/boardKey`]: boardKey,
  [`/lists/${listKey}/data`]: {
    createdAt: { '.sv': 'timestamp' },
    createdByUid: uid,
  },
  [`/boards/${boardKey}/lists/${listKey}/order`]: order,
});

// DELETE
export const generateValidDeleteListQuery = (
  listKey: string,
  boardKey: string,
  itemKeys: string[],
) => {
  const itemKeysObject = itemKeys.reduce(
    (acc: any, el) =>
      Object.assign(acc, {
        [`/items/${el}`]: null,
        [`/lists/${listKey}/items/${el}`]: null,
      }),
    {},
  );

  return Object.assign(
    {
      [`/lists/${listKey}/boardKey`]: null,
      [`/lists/${listKey}/data`]: null,
      [`/boards/${boardKey}/lists/${listKey}`]: null,
    },
    itemKeysObject,
  );
};

/**
 * SHORT_IDS
 */
export const generateValidCreateShortIdQuery = (boardKey: string, shortId: string) => ({
  [`/short_ids/${shortId}/boardKey`]: boardKey,
  [`/boards/${boardKey}/short_id`]: shortId,
});

/**
 * COLLECTIONS
 */
export const generateValidCreateCollectionQuery = (
  uid: string,
  collectionKeyArg: string,
  order: number,
) => ({
  [`/collections/${collectionKeyArg}/userKey`]: uid,
  [`/collections/${collectionKeyArg}/data/createdByUid`]: uid,
  [`/collections/${collectionKeyArg}/data/createdAt`]: { '.sv': 'timestamp' },
  [`/users/${uid}/collections/${collectionKeyArg}`]: { order },
});

export const generateValidDeleteCollectionQuery = (uid: string, collectionKeyArg: string) => ({
  [`/collections/${collectionKeyArg}/data`]: null,
  [`/collections/${collectionKeyArg}/userKey`]: null,
  [`/users/${uid}/collections/${collectionKeyArg}`]: null,
});

/**
 * USERS
 */

// CREATE
export const generateValidCreateUserQuery = (
  uid: string,
  defaultCollectionArg: string,
  collectionOrder: number,
) => ({
  [`/users/${uid}/data/createdByUid`]: uid,
  [`/users/${uid}/data/createdAt`]: { '.sv': 'timestamp' },
  [`/users/${uid}/data/lastLogin`]: { '.sv': 'timestamp' },
  [`/users/${uid}/data/defaultCollection`]: defaultCollectionArg,
  [`/users/${uid}/collections/${defaultCollectionArg}`]: { order: collectionOrder },
  [`/collections/${defaultCollectionArg}/userKey`]: uid,
  [`/collections/${defaultCollectionArg}/data/createdAt`]: { '.sv': 'timestamp' },
  [`/collections/${defaultCollectionArg}/data/createdByUid`]: uid,
});

// BoardExpiry
export const generateValidCreateBoardExpiryQuery = (
  boardKeyArg: string,
  uid: string,
  timestamp: number,
) => ({
  [`/boardExpiry/${boardKeyArg}/expiresAt`]: timestamp,
  [`/boardExpiry/${boardKeyArg}/expirySetByUid`]: uid,
});

export const generateValidUpdateBoardExpiryQuery = (
  boardKey: string,
  timestamp: number,
  uid: string,
) => ({
  [`/boardExpiry/${boardKey}/expiresAt`]: timestamp,
  [`/boardExpiry/${boardKey}/expirySetByUid`]: uid,
});

export const generateValidDeleteBoardExpiryQuery = (boardKey: string) => ({
  [`/boardExpiry/${boardKey}/expiresAt`]: null,
  [`/boardExpiry/${boardKey}/expirySetByUid`]: null,
  [`/boardExpiry/${boardKey}/updatePending`]: null,
  [`/boardExpiry/${boardKey}/deleteExpiry`]: null,
});

/**
 * BOARDPRESENCE
 */
// export const generateValidCreateBoardPresenceQuery = (
//   boardKeyArg: string,
//   uid: string,
//   connectionStatus: 'online' | 'offline',
//   offlineCount: number,
//   onlineCount: number,
// ) => ({
//   [`/boardPresence/${boardKeyArg}/${connectionStatus}/${uid}/lastActive`]: {
//     '.sv': 'timestamp',
//   },
//   [`/boardPresence/${boardKeyArg}/usersOfflineCount`]: offlineCount,
//   [`/boardPresence/${boardKeyArg}/usersOnlineCount`]: onlineCount,
// });

// export const generateValidUpdateBoardPresenceQuery = (
//   boardKeyArg: string,
//   uid: string,
//   connectionStatus: 'online' | 'offline',
//   offlineCount: number,
//   onlineCount: number,
// ) => ({
//   // Adding to
//   [`/boardPresence/${boardKeyArg}/${connectionStatus}/${uid}/lastActive`]: {
//     '.sv': 'timestamp',
//   },
//   // Deleting from
//   [`/boardPresence/${boardKeyArg}/${
//     connectionStatus === 'online' ? 'offline' : 'online' // switch
//   }/${uid}`]: null,
//   [`/boardPresence/${boardKeyArg}/usersOfflineCount`]: offlineCount,
//   [`/boardPresence/${boardKeyArg}/usersOnlineCount`]: onlineCount,
// });
