import { produce } from 'immer';
import { Action } from '../actions/index';
import { getType } from 'typesafe-actions';
import * as notificationActions from '../actions/notification.actions';

export interface INotification {
  title: string;
  message: string;
  level: 'info' | 'warn' | 'error';
  // relations to object keys to link stuff
  // read: false,
  // user (originating user, otherwise from system)
  // timestamp
  // timeout
}

export type NotificationsState = {
  [scope: string]: Array<INotification> | undefined;
};

export const notificationFactory = (obj = {}): INotification => ({
  title: '',
  message: '',
  level: 'info',
  ...obj,
});

export const initialState: NotificationsState = {};

export const notificationReducer = produce((draft: NotificationsState, action: Action) => {
  switch (action.type) {
    case getType(notificationActions.addNotificationSuccess): {
      const { scope, level, title, message } = action.payload;

      let notificationScope = draft[scope];
      if (!notificationScope) {
        draft[scope] = [];
        notificationScope = draft[scope];
      }

      if (notificationScope) {
        notificationScope.push(notificationFactory({ level, message, title }));
      }
      return;
    }

    case getType(notificationActions.deleteNotificationSuccess): {
      const { scope, index } = action.payload;
      let notificationScope = draft[scope];
      if (notificationScope) {
        notificationScope.splice(index, 1);
      }
      return;
    }

    case getType(notificationActions.clearNotificationsSuccess): {
      const { scope } = action.payload;

      if (scope) {
        delete draft[scope];
        return;
      }

      return initialState;
    }
  }

  // Immer isn't happy with returning the original state
  // and complains about 'not all code paths return a value'
  return;
}, initialState);
