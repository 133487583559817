import { createAction } from 'typesafe-actions';

export const selectPage = createAction('SELECT_PAGE', resolve => (pageName: string) =>
  resolve({ pageName }),
);

export const selectPreviousBoard = createAction(
  'SELECT_PREVIOUS_BOARD',
  resolve => (boardKey: string) => resolve({ boardKey }),
);

export const selectBoard = createAction('SELECT_BOARD', resolve => (boardKey: string) =>
  resolve({ boardKey }),
);

export const selectList = createAction('SELECT_LIST', resolve => (listKey: string) =>
  resolve({ listKey }),
);

export const selectAuthenticatedUser = createAction(
  'SELECT_AUTHENTICATED_USER',
  resolve => (uid: string) => resolve({ uid }),
);

export const setLoadingState = createAction(
  'SELECT_LOADING_STATE',
  resolve => (key: string, val: boolean) => resolve({ key, val }),
);
