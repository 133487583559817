import * as firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';

if (process.env.NODE_ENV === 'development') {
  firebase.database.enableLogging(function(arg) {
    // console.groupCollapsed('%c Firebase Event', 'color: #e67e22');
    console.debug('%cFirebase Log: ' + JSON.stringify(arg), 'color: #e67e22');
    // console.groupEnd();
  });
}

const firebaseApp = firebase.initializeApp({
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_RTDB_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  apiId: process.env.REACT_APP_FIREBASE_APP_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
});

export const FirebaseTimestamp = firebase.database.ServerValue.TIMESTAMP;

export default firebaseApp;
