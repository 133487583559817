import { eventChannel } from 'redux-saga';
import firebase, { FirebaseTimestamp } from '../config/firebase.config';
import { IBoard } from '../reducers/board.reducer';
import { IItem } from '../reducers/item.reducer';
import { IList } from '../reducers/list.reducer';
import { IUser } from '../reducers/user.reducer';
import { normalizeResponse } from './helpers.api';
import { SHORT_ID_DID_NOT_RESOLVE } from '../config/constants';
import { ICollection } from '../reducers/collection.reducer';
import {
  generateValidCreateBoardQuery,
  generateAddUserToBoardQuery,
  generateValidCreateUserQuery,
  generateValidCreateBoardExpiryQuery,
  generateValidUpdateBoardExpiryQuery,
  generateValidDeleteBoardExpiryQuery,
} from './queries';
import { IBoardExpiry } from 'reducers/boardExpiry.reducer';
import { IUserBoard } from 'reducers/userBoards.reducer';

// A lot of types here help us to add type safety to firebase
// responses. This was done as we are learning Typescript, so
// be mindful of problems that may arise here
export type FirebaseKeys = string | 'data' | null;
export type FirebaseValues<T> = Partial<T> | T[keyof T] | undefined;
export type FirebaseEvent<T> = {
  event: firebase.database.EventType;
  key: FirebaseKeys;
  values: FirebaseValues<T>;
};

export type ConnectionStatus = 'connected' | 'disconnected';

export type OrderedRelation = {
  [key: string]: OrderProperty;
};

export type OrderProperty = {
  order: number | boolean;
};

export type PresenceDataServerNode = {
  offline: {
    [key: string]: {
      lastActive: number;
    };
  };
  online: {
    [key: string]: {
      lastActive: number;
    };
  };
};

export type UserDataServerNode = Omit<IUser, 'collections'>;
export type CollectionDataServerNode = Omit<ICollection, 'boards'>;
export type BoardDataServerNode = Omit<IBoard, 'lists'>;
export type ListDataServerNode = Omit<IList, 'items'>;
export type ItemDataServerNode = IItem;
export type BoardExpiryServerNode = IBoardExpiry;

export type ServerResponse =
  | Partial<BoardDataServerNode>
  | Partial<ListDataServerNode>
  | Partial<ItemDataServerNode>
  | Partial<UserDataServerNode>
  | Partial<BoardExpiryServerNode>
  | Partial<IUserBoard>
  | OrderedRelation;

export type FirebaseEvents =
  | 'value'
  | 'child_added'
  | 'child_moved'
  | 'child_changed'
  | 'child_removed';

export type UserBoardsSubscriptionResponse = {
  key: string;
  event: FirebaseEvents;
  values: { [key: string]: Partial<IUserBoard> } | Partial<IUserBoard>;
};

export type FirebaseObjectTypes = IBoard | IList | IItem | IUser | ICollection | IBoardExpiry;
export type FirebaseRefTypes =
  | 'collections'
  | 'boards'
  | 'users'
  | 'items'
  | 'lists'
  | 'boardExpiry'
  | 'userBoards';

export type FirebaseChildrenRefTypes = 'collections' | 'boards' | 'items' | 'lists' | 'data';

export const createUserObject = (user: firebase.User) => ({
  uid: user.uid,
  isAnonymous: user.isAnonymous,
  displayName: user.displayName,
  email: user.email,
  emailVerified: user.emailVerified,
});

export type ObjectWithOrderProperty = { order: number };
export type ObjectWithOrderAndStarredProperty = { order: number; starred?: boolean };
export type ListOfObjectsWithOrderProperty = {
  [key: string]: ObjectWithOrderProperty;
};
export type ListOfObjectsWithOrderAndStarredProperty = {
  [key: string]: ObjectWithOrderAndStarredProperty;
};
export type ChildRelationResponse = {
  event: string;
  key: string;
  values: ListOfObjectsWithOrderProperty | ObjectWithOrderProperty | null;
};

/**
 * A type guard to differentiate between two kinds of server responses:
 * 1. Where server returns one object with nested objects with order property
 * 2. Where server returns object with order property
 * (3. null)
 */
export const isObjectWithOrderProperty = (
  obj: ObjectWithOrderProperty | ListOfObjectsWithOrderProperty,
): obj is ObjectWithOrderProperty => {
  // Check if order property is set, then make sure it's a string (technically, it's a valid key in ListOfObjectsWithOrderProperty
  // with an object assigned to it.
  return (obj as ObjectWithOrderProperty).order !== undefined && typeof obj.order === 'number';
};

// Additional type guards
export const isDataObject = <T>(
  key: FirebaseKeys,
  values: FirebaseValues<T>,
): values is Partial<T> => {
  return key === 'data' && typeof values === 'object';
};

export const isObject = <T>(values: FirebaseValues<T>): values is Partial<T> => {
  return typeof values === 'object';
};

export const isChildNode = <T>(
  key: FirebaseKeys,
  values: FirebaseValues<T>,
): values is T[keyof T] => {
  return (
    key !== 'data' &&
    (typeof values === 'string' || typeof values === 'number' || typeof values === 'boolean')
  );
};

export const deleteBoard = (boardKey: string) => {
  return firebase
    .database()
    .ref(`/boards/${boardKey}/isDeleting`)
    .set(true);
};

// Authentication
export function observeAuthentication() {
  return eventChannel(emitter => {
    return firebase.auth().onAuthStateChanged(
      user => {
        if (user) {
          const userObject = createUserObject(user);

          if (user.isAnonymous) {
            return emitter({
              user: userObject,
              event: 'anonymous_sign_in',
            });
          }

          return emitter({
            user: userObject,
            event: 'email_auth_provider_sign_in',
          });
        }

        return emitter({
          event: 'unknown_user',
        });
      },
      error => console.error(error),
      () => console.log('completed, should close channel'),
    );
  });
}

export function sendPasswordResetEmail(email: string) {
  return firebase.auth().sendPasswordResetEmail(email);
}

export function signInAnonymously() {
  return firebase.auth().signInAnonymously();
}

export function convertAnonymousToPermanentEmailAccount(email: string, password: string) {
  // @ts-ignore: we know this exists
  const credential = firebase.auth.EmailAuthProvider.credential(email, password);

  const currentUser = firebase.auth().currentUser;

  if (currentUser) {
    currentUser.linkWithCredential(credential).then(
      // @ts-ignore: I don't know what this is about
      user => user.sendEmailVerification().then(() => createUserObject(user)),
      (error: Error) => {
        throw error;
      },
    );
  }
}

export function signInWithEmailAndPassword(email: string, password: string) {
  return firebase.auth().signInWithEmailAndPassword(email, password);
}

export function signOut(): Promise<void | Error> {
  return firebase
    .auth()
    .signOut()
    .then()
    .catch(err => err);
}

export function getBoardKey(shortId: string): string | Promise<Error> {
  return firebase
    .database()
    .ref(`/short_ids/${shortId}/boardKey`)
    .once('value')
    .then(snapshot => {
      if (!snapshot || !snapshot.val()) {
        throw new Error(SHORT_ID_DID_NOT_RESOLVE);
      }

      return snapshot.val();
    });
}

export function createUser(uid: string, defaultCollectionKey: string, collectionOrder = 0) {
  const query = generateValidCreateUserQuery(uid, defaultCollectionKey, collectionOrder);

  return firebase
    .database()
    .ref()
    .update(query);
}

export function createBoardExpiry(uid: string, boardKey: string, timestamp: number) {
  const query = generateValidCreateBoardExpiryQuery(boardKey, uid, timestamp);

  return firebase
    .database()
    .ref()
    .update(query);
}

export function updateBoardExpiry(uid: string, boardKey: string, timestamp: number) {
  const query = generateValidUpdateBoardExpiryQuery(boardKey, timestamp, uid);

  return firebase
    .database()
    .ref()
    .update(query);
}

export function deleteBoardExpiry(boardKey: string) {
  return firebase
    .database()
    .ref()
    .update({
      [`/boardExpiry/${boardKey}/deleteExpiry`]: true,
    });
}

// Update firebase auth user profile
export function updateFirebaseUserProfile(payload: firebase.User) {
  const currentUser = firebase.auth().currentUser;
  if (currentUser) {
    return currentUser.updateProfile(payload);
  }

  throw new Error('User not found');
}

export async function createBoardPermissions(
  uid: string,
  role: 'owners' | 'admins' | 'authors' | 'subscribers',
  boardKey: string,
  collectionKey: string,
) {
  const boardOrderInCollection = await getNextOrder(
    'collections',
    collectionKey,
    'boards',
    boardKey,
  );

  const query = generateAddUserToBoardQuery(
    boardKey,
    uid,
    role,
    collectionKey,
    boardOrderInCollection || 0,
  );

  return firebase
    .database()
    .ref()
    .update(query);
}

export async function createBoardAndOwnerPermissions(
  uid: string,
  boardKey: string,
  collectionKey: string,
) {
  const boardOrderInCollection = await getNextOrder(
    'collections',
    collectionKey,
    'boards',
    boardKey,
  );

  const query = generateValidCreateBoardQuery(
    uid,
    'owners',
    collectionKey,
    boardKey,
    boardOrderInCollection || 0,
  );

  return firebase
    .database()
    .ref()
    .update(query);
}

export function getBoardUserRole(uid: string, boardKey: string) {
  return firebase
    .database()
    .ref(`/userBoards/${uid}/${boardKey}/role`)
    .once('value')
    .then(snapshot => snapshot.val());
}

// Object interactions
export function generateKey(objRef: FirebaseRefTypes) {
  return firebase
    .database()
    .ref(objRef)
    .push().key;
}

export async function createObjectInOrderedCollection(
  uid: string,
  parentRefType: FirebaseRefTypes,
  parentKey: string,
  objRefType: FirebaseChildrenRefTypes,
  objKey: string,
  insertAfterKey: string | undefined,
  payload?: { [key: string]: string | number | boolean },
) {
  const nextOrder = await getNextOrder(
    parentRefType,
    parentKey,
    objRefType,
    objKey,
    insertAfterKey,
  );

  return firebase
    .database()
    .ref()
    .update({
      // If answer is undefined, in this context, we want to add the first item
      [`${parentRefType}/${parentKey}/${objRefType}/${objKey}/order`]: nextOrder || 0,
      [`${objRefType}/${objKey}/data/createdByUid`]: uid,
      [`${objRefType}/${objKey}/data/createdAt`]: FirebaseTimestamp,
      ...payload,
    });
}

// A little helper to calculate the next order prop we need to set for a given object
// Goes and reads some stuff from the server before doing so
async function getNextOrder(
  parentRefType: FirebaseRefTypes,
  parentKey: string,
  childRefType: FirebaseChildrenRefTypes,
  childKey: string,
  insertAfterKey?: string,
) {
  let nextOrder;

  // If insertAfterKey is not set, we assume the user it adding an item
  // to the top of the list. We therefore need to fetch the first result
  // in said list and decrement its order by 1
  if (!insertAfterKey) {
    await firebase
      .database()
      .ref(`/${parentRefType}/${parentKey}/${childRefType}`)
      .orderByChild('order')
      .limitToFirst(1)
      .once('value')
      .then(snapshot =>
        snapshot.forEach(childSnapshot => {
          if (!isNaN(childSnapshot.val().order)) {
            nextOrder = Math.floor(childSnapshot.val().order) - 1;
          }
        }),
      );

    // If nextOrder isn't defined, assume it's an empty list
    if (!nextOrder) {
      nextOrder = 0;
    }
  } else {
    // Otherwise we need to fetch the insertAfterKey node and establish
    // whether the user intends to add an item to the bottom or somewhere in
    // the middle
    let minMaxOrder: number[] = [];

    // Firebase requires us to know the value of order of insertAfterKey
    // to sort and limit
    const insertAfterKeyNode = await firebase
      .database()
      .ref(`/${parentRefType}/${parentKey}/${childRefType}/${insertAfterKey}`)
      .once('value');

    if (!insertAfterKeyNode.val()) {
      throw new Error('insertAfterKey not found');
    }

    const insertAfterKeyOrder: number = insertAfterKeyNode.val().order;

    // We'll populate this array with the resulting keys for reasons
    // explained below
    let orderedKeys: string[] = [];

    // Getting two rows if possible
    await firebase
      .database()
      .ref(`/${parentRefType}/${parentKey}/${childRefType}`)
      .orderByChild('order')
      .startAt(insertAfterKeyOrder, insertAfterKey)
      .limitToFirst(2)
      .once('value')
      .then(snapshot =>
        snapshot.forEach(childSnapshot => {
          if (!isNaN(childSnapshot.val().order)) {
            orderedKeys = childSnapshot.key ? [...orderedKeys, childSnapshot.key] : orderedKeys;
            minMaxOrder = [...minMaxOrder, childSnapshot.val().order];
          }
        }),
      );

    // If the two keys are already adjacent in the desired
    // order, we do not change their values
    if (orderedKeys[0] === insertAfterKey && orderedKeys[1] === childKey) {
      return;
    }
    // When two values (rows) are present, we make a sandwich
    if (minMaxOrder.length === 2) {
      nextOrder = (minMaxOrder[0] + minMaxOrder[1]) / 2;
    }

    // When only one value (row) present, we can assume people are
    // planning to add object to the end
    if (minMaxOrder.length === 1) {
      // Rounding it down and increment by one
      nextOrder = Math.floor(minMaxOrder[0]) + 1;
    }
  }
  return nextOrder;
}

export async function createObject(
  objRef: FirebaseRefTypes,
  uid: string,
  key: string,
  payload?: { [key: string]: string | number | boolean },
) {
  // When insertAfterKey is set, we need to talk to the server

  return firebase
    .database()
    .ref()
    .update({
      [`${objRef}/${key}/data/createdByUid`]: uid,
      [`${objRef}/${key}/data/createdAt`]: FirebaseTimestamp,
      ...payload,
    });
}

export function updateObject(
  objRef: FirebaseRefTypes,
  uid: string,
  key: string,
  payload?: Partial<FirebaseObjectTypes>,
): Promise<void> {
  return firebase
    .database()
    .ref(`${objRef}/${key}/data`)
    .update({
      updatedAt: FirebaseTimestamp,
      lastUpdateUid: uid,
      ...payload,
    });
}

export function getUserData(uid: string) {
  return firebase
    .database()
    .ref(`/users/${uid}/data`)
    .once('value')
    .then(snapshot => snapshot.val());
}

export async function moveObjectChild(
  uid: string,
  parentRefType: FirebaseRefTypes,
  currentParentKey: string, // the current parent node
  targetParentKey: string | null = null, // If this is set, we'll move the item to another parent
  childRelationRefType: Exclude<FirebaseChildrenRefTypes, 'data'>, // data ain't ordered collection
  childKey: string,
  insertAfterKey?: string,
) {
  // We will set this to the target parent key, which may be another object
  const nextParentKey = targetParentKey ? targetParentKey : currentParentKey;

  const nextOrder = await getNextOrder(
    parentRefType,
    nextParentKey,
    childRelationRefType,
    childKey,
    insertAfterKey,
  );

  // When undefined, we don't want to do anything
  // because Items are in correct order
  if (nextOrder === undefined) {
    return;
  }

  // We use this key to create a 1:1 association between a child and its parent
  const parentRelationKeyName = `${parentRefType.slice(0, -1)}Key`;

  // When moving, make it part of query
  const changeParent =
    targetParentKey && currentParentKey !== targetParentKey
      ? {
        [`/${childRelationRefType}/${childKey}/${parentRelationKeyName}`]: targetParentKey,
      }
      : {};

  // If we are changing parent, delete from current one
  const deletePath =
    targetParentKey && currentParentKey !== targetParentKey
      ? {
        [`/${parentRefType}/${currentParentKey}/${childRelationRefType}/${childKey}`]: null,
      }
      : {};

  return firebase
    .database()
    .ref(`/`)
    .update({
      [`/${parentRefType}/${nextParentKey}/${childRelationRefType}/${childKey}/order`]: nextOrder,
      [`/${childRelationRefType}/${childKey}/data/movedAt`]: FirebaseTimestamp,
      [`/${childRelationRefType}/${childKey}/data/lastUpdateUid`]: uid,
      ...changeParent,
      ...deletePath,
    });
}

export function updateEmail(nextEmail: string, password: string) {
  const user = firebase.auth().currentUser;

  if (user && user.email) {
    // @ts-ignore
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, password);

    return user.reauthenticateWithCredential(credential).then(async () => {
      await user.updateEmail(nextEmail);
      return await user.sendEmailVerification();
    });
  }
  return;
}

export function updatePassword(currentPassword: string, nextPassword: string) {
  const user = firebase.auth().currentUser;

  if (user && user.email) {
    // @ts-ignore
    const credential = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);

    return user.reauthenticateWithCredential(credential).then(() => {
      return user.updatePassword(nextPassword);
    });
  }
  return;
}

/**
 * Subscribes to an object (Firebase Reference)
 *
 * @param objRef - The Firebase Reference Type
 * @param key - The key in the Firebase tree
 * @childrenRefType - The child to subscribe, optional
 * @paginationOptions - Optional.
 */
type PaginationOptions = {
  enableOverride?: boolean;
  orderBy?: 'order' | keyof IUserBoard;
  limit?: number;
  sort?: 'ASC' | 'DESC';
  childValue?: number;
  childKey?: string;
};

export function subscribeObject(
  objRef: FirebaseRefTypes,
  key: string,
  // childrenRef is optional, but you might want to paginate without it, so setting to empty string
  childrenRefType: FirebaseChildrenRefTypes | '' = '',
  // NOTE: Once I've improved my TS skills, I should type this better to make sure the user
  // understands that you can't just sent in e.g. limit
  paginationOptions: PaginationOptions = {},
) {


  // We use once('value') to read all values in one go. For reasons that
  // not entirely clear to me, Firebase expects us to handle child_added
  // with all these values before emitting the value event.
  // This solution only solves the issue on the front end. We will only
  // emit child_added after initialLoadComplete has been set to true.
  let initialLoadComplete = false;

  // Pagination in Firebase Real Time Database is painful, therefore we aim to
  // encapsulate it into this function and make this function look more bearable to the
  // the outside, though still not easy to use at this point. So here's what happens:

  // 1. We get the desired ref
  // In case we want a specific node
  const listenerRef: firebase.database.Reference = firebase.database().ref(`/${objRef}/${key}/`);

  // If parameters provided, type changes to query
  let query: firebase.database.Query | undefined;


  // At the present, we only allow pagination for children of a major node
  // or where specifically overriden. This is a later, slightly unfortunate addition
  // because of userBoards
  if (childrenRefType || paginationOptions.enableOverride) {
    if (childrenRefType) {
      query = listenerRef.child(childrenRefType);
    } else {
      query = listenerRef;
    }

    // 2. We set the ordering mechanism, which defaults to orderByKey
    if (paginationOptions.orderBy) {
      query = query.orderByChild(paginationOptions.orderBy);
    } else {
      query = query.orderByKey();
    }

    // 3. Limits, defaults to ascending order
    if (paginationOptions.limit) {
      if (paginationOptions.sort === 'DESC') {
        // Will take the last x from the bottom
        query = query.limitToLast(paginationOptions.limit);
      } else {
        // Default for Firebase, pick from the top
        query = query.limitToFirst(paginationOptions.limit);
      }
    }

    // 4. Decide on the flavor of pagination.
    if (paginationOptions.sort === 'ASC' && paginationOptions.childValue != null) {
      // We need to use the value of the child to sort by
      // that is either the key in orderByKey or the value of orderByChild
      // and if the values of the latter are the same (e.g. we sort by highscore
      // and multiple people have the same score), we need to provide the key
      if (paginationOptions.childKey) {
        query = query.startAt(paginationOptions.childValue, paginationOptions.childKey);
      } else {
        query = query.startAt(paginationOptions.childValue);
      }
    } else if (paginationOptions.sort === 'DESC' && paginationOptions.childValue != null) {
      // Read above to understand why this is the way it is
      if (paginationOptions.childKey) {
        query = query.endAt(paginationOptions.childValue, paginationOptions.childKey);
      } else {
        query = query.endAt(paginationOptions.childValue);
      }
    }
  }


  // If query has been populated, use that
  const request = query ? query : listenerRef;

  return eventChannel(emitter => {
    request.once('value', snapshot => {

      initialLoadComplete = true;

      const values = normalizeResponse(snapshot.val());

      return emitter({
        event: 'value',
        key: snapshot.key,
        values,
      });
    });

    request.on('child_changed', snapshot => {
      let values: unknown;

      if (childrenRefType !== 'data' && childrenRefType !== '') {
        values = normalizeResponse(snapshot.val());
      } else {
        values = snapshot.val();
      }

      return emitter({
        event: 'child_changed',
        key: snapshot.key,
        values,
      });
    });

    request.on('child_added', snapshot => {
      if (!initialLoadComplete) {
        return;
      }

      let values: unknown;

      if (childrenRefType !== 'data' && childrenRefType !== '') {
        values = normalizeResponse(snapshot.val());
      } else {
        values = snapshot.val();
      }

      return emitter({
        event: 'child_added',
        key: snapshot.key,
        values,
      });
    });

    request.on('child_removed', snapshot => {
      return emitter({
        event: 'child_removed',
        key: snapshot.key,
      });
    });

    // Unsubscribe
    return () => request.off();
  });
}

export function deleteObject(objRef: FirebaseRefTypes, key: string, payload: object = {}) {
  // Briefly explored the option of making a round trip to the server to determine
  // the current children of a given object, but in the end we decided against to reduce
  // the number of reads on Firebase and also to err on the side of caution re deleting
  // e.g. lists that have changed considerably.
  const update = {
    [`/${objRef}/${key}/data`]: null,
    ...payload, // for any quirks in deleting objects
  };

  return firebase
    .database()
    .ref()
    .update(update);
}

// Presence
// Manages connection status and user presence in a board
export function subscribeConnectionStatus(uid: string, boardKey: string) {
  console.log('fix me');
  return;
  // return eventChannel(emitter => {
  //   const ref = firebase.database().ref('.info/connected');

  //   ref.on('value', snapshot => {
  //     if (!snapshot) {
  //       return;
  //     }
  //     if (snapshot.val() === true) {
  //       firebase
  //         .database()
  //         .ref(`boards/${boardKey}/presence/offline/${uid}`)
  //         .once('value')
  //         .then(dataSnapshot => {
  //           const previousValues = dataSnapshot.val();
  //           const boardPresenceRef = firebase.database().ref(`boards/${boardKey}/presence`);

  //           let onDisconnectUpdates: { [index: string]: Object | null } = {}; // Firebase Timestamp is an object
  //           onDisconnectUpdates[`online/${uid}`] = null;
  //           onDisconnectUpdates[`offline/${uid}/lastActive`] = FirebaseTimestamp;
  //           boardPresenceRef.onDisconnect().update(onDisconnectUpdates);

  //           let onConnectUpdates: { [index: string]: Object | null } = {};
  //           onConnectUpdates[`offline/${uid}`] = null;
  //           onConnectUpdates[`online/${uid}/lastActive`] = FirebaseTimestamp;
  //           boardPresenceRef.update(onConnectUpdates);

  //           return emitter({
  //             status: 'connected',
  //             lastActive: previousValues && previousValues.lastActive,
  //           });
  //         });
  //     } else {
  //       return emitter({
  //         status: 'disconnected',
  //       });
  //     }
  // });

  // return () => ref.off();
  // });
}

export function subscribePresence(boardKey: string) {
  // Tried the following, but didn't work so well ==> would not receive updates
  // const ref = firebase.database().ref(`boards/${boardKey}/presence/users`).orderByChild('status').equalTo('connected');

  const ref = firebase.database().ref(`boards/${boardKey}/presence`);
  return eventChannel(emitter => {
    ref.once('value', snapshot => {
      const values = normalizeResponse(snapshot.val());
      return emitter({
        event: 'value',
        key: snapshot.key,
        values,
      });
    });

    ref.on('child_changed', snapshot => {
      if (!snapshot) {
        return;
      }
      const values = normalizeResponse(snapshot.val());

      return emitter({
        event: 'child_changed',
        key: snapshot.key,
        values,
      });
    });

    return () => ref.off();
  });
}

export function setLastActive(uid: string, boardKey: string, timestamp: number) {
  const ref = firebase.database().ref(`boards/${boardKey}/presence/online/${uid}`);
  return ref.update({
    lastActive: timestamp,
  });
}
