import { original, produce } from 'immer';
import { getType } from 'typesafe-actions';

import * as actions from '../actions';
import { ListOfObjectsWithOrderProperty } from '../api/firebase.api';

export interface IBoardExpiry {
  expiresAt?: number | null;
  expirySetByUid?: string;
  updatePending: boolean;
  deleteExpiry: boolean;
}

export type BoardsState = {
  [key: string]: IBoardExpiry | undefined;
};

export const boardExpiryFactory = (obj = {}): IBoardExpiry => ({
  updatePending: false,
  deleteExpiry: false,
  ...obj,
});

export const initialState: BoardsState = {};

export const boardExpiryReducer = produce((draft: BoardsState, action: actions.Action) => {
  switch (action.type) {
    case getType(actions.addBoardExpirySuccess): {
      const { expiresAt, uid, boardKey } = action.payload;
      draft[boardKey] = boardExpiryFactory({
        expiresAt,
        expirySetByUid: uid,
      });
      return;
    }

    case getType(actions.pushBoardExpirySuccess): {
      const { boardKey, boardExpiry: boardExpiryServerResponse } = action.payload;

      let boardExpiry = boardExpiryServerResponse;

      // If boardExpiry returns null, set expiresAt to null
      if (boardExpiry === null) {
        boardExpiry = boardExpiryFactory({
          expiresAt: null,
          expirySetByUid: undefined,
        })
      }

      draft[boardKey] = boardExpiryFactory({
        ...original(draft[boardKey]),
        ...boardExpiry,
      });
      return;
    }

    case getType(actions.updateBoardExpirySuccess): {
      // We are currently not using this because
      // we'd rather await the response from the server
      return;
    }

    case getType(actions.deleteBoardExpirySuccess): {
      // We don't actually delete the node because we need to keep track
      // of whether it's been loaded, so it's more of a reset-situation
      const { boardKey } = action.payload;

      draft[boardKey] = boardExpiryFactory({
        expiresAt: null // null indicates that we've loaded from the server
      })

      return;
    }
  }
}, initialState);
