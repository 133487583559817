import { produce } from 'immer';
import { getType } from 'typesafe-actions';

import {
  selectAuthenticatedUser,
  selectBoard,
  selectList,
  selectPage,
  setLoadingState,
  selectPreviousBoard,
} from '../actions/ui.actions';
import { Action } from '../actions';

export interface UIState {
  previousBoard?: string;
  selectedPage?: string;
  selectedBoard?: string;
  selectedList?: string;
  authenticatedUser?: string;
  loading: { [key: string]: boolean | undefined };
}

export const uiFactory = (obj = {}): UIState => ({
  selectedPage: 'landingPage',
  loading: {},
  ...obj,
});

export const initialState: UIState = uiFactory();

export const uiReducer = produce((draft: UIState, action: Action) => {
  switch (action.type) {
    case getType(selectPage): {
      const { pageName } = action.payload;
      draft.selectedPage = pageName;
      return;
    }

    case getType(selectBoard): {
      const { boardKey } = action.payload;
      draft.selectedBoard = boardKey;
      return;
    }

    case getType(selectPreviousBoard): {
      const { boardKey } = action.payload;
      draft.previousBoard = boardKey;
      return;
    }

    case getType(selectList): {
      const { listKey } = action.payload;
      draft.selectedList = listKey;
      return;
    }

    case getType(selectAuthenticatedUser): {
      const { uid } = action.payload;
      draft.authenticatedUser = uid;
      return;
    }

    // Sometimes, we need redux to explicitly set loading state
    case getType(setLoadingState): {
      const { key, val } = action.payload;
      const loading = draft.loading;
      if (loading) {
        loading[key] = val;
      }
      return;
    }
  }
}, initialState);
