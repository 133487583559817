import { boardReducer, initialState as boardInitialState } from '../reducers/board.reducer';
import {
  collectionReducer,
  initialState as collectionInitialState,
} from '../reducers/collection.reducer';
import { errorReducer, initialState as errorInitialState } from '../reducers/error.reducer';
import { initialState as itemInitialState, itemReducer } from '../reducers/item.reducer';
import { initialState as listInitialState, listReducer } from '../reducers/list.reducer';
import {
  initialState as notificationInitialState,
  notificationReducer,
} from '../reducers/notification.reducer';
import {
  initialState as presenceInitialState,
  presenceReducer,
} from '../reducers/presence.reducer';
import {
  initialState as boardExpiryInitialState,
  boardExpiryReducer,
} from 'reducers/boardExpiry.reducer';
import { initialState as uiInitialState, uiReducer } from '../reducers/ui.reducer';
import { initialState as userInitialState, userReducer } from '../reducers/user.reducer';
import {
  initialState as userBoardsInitialState,
  userBoardReducer,
} from '../reducers/userBoards.reducer';

export const baseReducer = {
  boards: boardReducer,
  collections: collectionReducer,
  lists: listReducer,
  items: itemReducer,
  users: userReducer,
  userBoards: userBoardReducer,
  presence: presenceReducer,
  ui: uiReducer,
  errors: errorReducer,
  notifications: notificationReducer,
  boardExpiry: boardExpiryReducer,
};

// We import the initial state from each reducer
// for cases where that may be required
export const initialState = {
  boards: boardInitialState,
  lists: listInitialState,
  items: itemInitialState,
  collections: collectionInitialState,
  users: userInitialState,
  presence: presenceInitialState,
  ui: uiInitialState,
  errors: errorInitialState,
  notifications: notificationInitialState,
  boardExpiry: boardExpiryInitialState,
  userBoards: userBoardsInitialState,
};
