import produce from 'immer';
import * as actions from '../actions';
import { getType } from 'typesafe-actions';
import { original } from 'immer';

export interface IItem {
  title: string;
  completed: boolean;
  createdAt: number;
  updatedAt: number;
  movedAt: number;
  lastUpdateUid?: string;
  createdByUid: string;
  fresh: boolean;
  dirtyFields: Set<string>; // technically should be `typeof IItem`, but Typescript won't allow me to use Object.keys() easily
}

export type ItemsState = {
  [key: string]: IItem | undefined;
};

export const initialState: ItemsState = {};

export const itemFactory = (obj = {}): IItem => ({
  title: '',
  completed: false,
  createdAt: 0,
  updatedAt: 0,
  movedAt: 0,
  createdByUid: '',
  fresh: false,
  dirtyFields: new Set([]),
  ...obj,
});

export const itemReducer = produce((draft: ItemsState, action: actions.Action) => {
  switch (action.type) {
    case getType(actions.addItemSuccess): {
      const { itemKey } = action.payload;
      draft[itemKey] = itemFactory({ fresh: true });
      return;
    }

    case getType(actions.pushItemSuccess): {
      const { itemKey, item } = action.payload;

      draft[itemKey] = itemFactory({
        ...original(draft[itemKey]),
        ...item,
      });

      const dirtyFields = draft[itemKey] && draft[itemKey]!.dirtyFields;

      // Remove pushed fields from dirty. We do not check for field equality
      // because we assume the server sent the latest version
      if (dirtyFields && dirtyFields.size > 0) {
        // Get the pushed keys
        Object.keys(item).map(key => {
          // Check if they are considered dirty
          if (dirtyFields.has(key)) {
            dirtyFields.delete(key);
            draft[itemKey]!.dirtyFields = dirtyFields;
          }
          return null;
        });
      }

      return;
    }

    case getType(actions.updateItemSuccess): {
      const { itemKey, item } = action.payload;

      draft[itemKey] = itemFactory({
        ...original(draft[itemKey]),
        ...item,
      });

      // Merge newly updated fields with already dirty ones
      draft[itemKey]!.dirtyFields = new Set([...draft[itemKey]!.dirtyFields, ...Object.keys(item)]);

      return;
    }

    case getType(actions.deleteItemSuccess): {
      const { itemKey } = action.payload;
      delete draft[itemKey];
      return;
    }
  }
}, initialState);
