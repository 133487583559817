import { createAction } from 'typesafe-actions';

export const addNotification = createAction(
  'ADD_NOTIFICATION',
  resolve => (scope: string, level: string, title: string, message: string, timeout?: number) =>
    resolve({ scope, level, title, message, timeout }),
);

export const addNotificationSuccess = createAction(
  'ADD_NOTIFICATION_SUCCESS',
  resolve => (scope: string, level: string, title: string, message: string) =>
    resolve({ scope, level, title, message }),
);

export const addNotificationError = createAction(
  'ADD_NOTIFICATION_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const deleteNotificationSuccess = createAction(
  'DELETE_NOTIFICATION_SUCCESS',
  resolve => (scope: string, index: number) => resolve({ scope, index }),
);

export const clearNotificationsSuccess = createAction(
  'CLEAR_NOTIFICATIONS_SUCCESS',
  resolve => (scope: string = '') => resolve({ scope }),
);
