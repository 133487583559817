import { fork, takeLatest, call, cancel, put, take, takeEvery } from 'redux-saga/effects';
import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import * as api from '../api/firebase.api';
import { isDataObject, isChildNode, FirebaseEvent, ListDataServerNode } from '../api/firebase.api';

export default [
  takeEvery(getType(actions.addList), addListSaga),
  takeEvery(getType(actions.addItemToList), addItemToListSaga),
  takeEvery(getType(actions.pushItemToList), pushItemToListSaga),
  takeEvery(getType(actions.deleteList), deleteListSaga),
  takeEvery(getType(actions.deleteItemFromList), deleteItemFromListSaga),
  takeEvery(getType(actions.moveListItem), moveListItemSaga),
  takeLatest(getType(actions.pushList), pushListSaga),
  takeEvery(getType(actions.subscribeList), subscribeListSaga),
  takeEvery(getType(actions.subscribeListItems), subscribeListItemsSaga),
  takeEvery(getType(actions.updateList), updateListSaga),
  takeEvery(
    [
      getType(actions.addListError),
      getType(actions.addItemToListError),
      getType(actions.pushItemToListError),
      getType(actions.deleteListError),
      getType(actions.deleteItemFromListError),
      getType(actions.moveListItemError),
      getType(actions.pushListError),
      getType(actions.subscribeListError),
      getType(actions.subscribeListItemsError),
      getType(actions.updateListError),
    ],
    listErrorsSaga,
  ),
];

export function* subscribeListSaga(action: ReturnType<typeof actions.subscribeList>) {
  try {
    const task = yield call(subscribeListTask, action.payload.boardKey, action.payload.listKey);

    while (true) {
      const unsubscribedList = yield take(getType(actions.unsubscribeList));

      if (action.payload.listKey === unsubscribedList.listKey) {
        yield cancel(task);
        yield put(actions.deleteList(action.payload.boardKey, action.payload.listKey, [], false)); // only delete in state
        break;
      }
    }
  } catch (err) {
    yield put(actions.subscribeListError(err));
  }
}

export function* subscribeListTask(boardKey: string, listKey: string) {
  const chan = yield call(api.subscribeObject, 'lists', listKey, 'data');

  try {
    while (true) {
      const { key, event, values }: FirebaseEvent<ListDataServerNode> = yield take(chan);

      switch (event) {
        case 'value': {
          if (isDataObject(key, values)) {
            yield put(actions.pushList(boardKey, listKey, values));
            yield put(actions.subscribeListItems(listKey));
          } else {
            throw new Error('Key returned from server is not handled here');
          }
          break;
        }

        case 'child_changed': {
          if (key && isChildNode(key, values)) {
            yield put(actions.pushList(boardKey, listKey, { [key]: values }));
          } else {
            throw new Error('Key is not set or not child node returned');
          }
          break;
        }

        case 'child_added': {
          if (key && isChildNode(key, values)) {
            yield put(actions.pushList(boardKey, listKey, { [key]: values }));
          } else {
            throw new Error('Key is not set or not child node returned');
          }
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.subscribeListError(err));
  } finally {
    // Detach listener when task cancelled via UNSUBSCRIBE_LIST
    yield chan.close();
  }
}

export function* pushListSaga(action: ReturnType<typeof actions.pushList>) {
  try {
    const { listKey, list } = action.payload;
    yield put(actions.pushListSuccess(listKey, list));
  } catch (err) {
    yield put(actions.pushListError(err));
  }
}

export function* subscribeListItemsSaga(action: ReturnType<typeof actions.subscribeListItems>) {
  try {
    const task = yield fork(subscribeListItemsTask, action.payload.listKey);

    while (true) {
      const unsubscribedList = yield take(getType(actions.unsubscribeList));

      if (action.payload.listKey === unsubscribedList.listKey) {
        yield cancel(task);
        break;
      }
    }
  } catch (err) {
    yield put(actions.subscribeListItemsError(err));
  }
}

export function* subscribeListItemsTask(listKey: string) {
  const chan = yield call(api.subscribeObject, 'lists', listKey, 'items', { orderBy: 'order' });
  try {
    while (true) {
      const { event, key, values }: api.ChildRelationResponse = yield take(chan);

      switch (event) {
        case 'value': {
          if (key === 'items') {
            if (values && !api.isObjectWithOrderProperty(values)) {
              // Should be pushListSuccess instead
              yield put(actions.pushListSuccess(listKey, { items: values }));
            } else {
              throw new Error('Values returned by server are null or not processed');
            }
          } else {
            throw new Error('Key returned from server is not in use');
          }
          break;
        }

        case 'child_added': {
          if (values && api.isObjectWithOrderProperty(values)) {
            yield put(actions.pushItemToList(listKey, key, values.order));
          } else {
            throw new Error('Values returned by server are null or not processed');
          }
          break;
        }

        case 'child_changed': {
          // first argument should be uid, but we don't need it for local state
          // No targetlist required because Firebase only considers it a move
          // event when moved within same node.
          if (values && api.isObjectWithOrderProperty(values)) {
            yield put(actions.pushItemToList(listKey, key, values.order));
          } else {
            throw new Error('Values returned by server are not processed');
          }
          break;
        }

        case 'child_removed': {
          yield put(actions.deleteItemFromList(listKey, key));
          break;
        }

        default: {
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.subscribeListItems(err));
  } finally {
    // Detach listener upon UNSUBSCRIBE_LIST
    yield chan.close();
  }
}

export function* updateListSaga(action: ReturnType<typeof actions.updateList>) {
  try {
    const { listKey, list, uid, persist } = action.payload;
    yield put(actions.updateListSuccess(listKey, list));

    if (persist) {
      yield call(api.updateObject, 'lists', uid, listKey, list);
    }
  } catch (err) {
    yield put(actions.updateListError(err));
  }
}

export function* addListSaga(action: ReturnType<typeof actions.addList>) {
  try {
    const { boardKey, index, uid } = action.payload;
    const listKey = yield call(api.generateKey, 'lists'); // blocking call, but does not travel to server according to docs

    const task = yield fork(addListTask, uid, listKey, boardKey, index);

    // There is a race effect in redux saga, but I'm not sure how I can verify
    // the keys there, so this is my solution
    while (true) {
      // We await either of these events, where pushList indicates a server event
      const race: ReturnType<typeof actions.pushList | typeof actions.deleteList> = yield take([
        getType(actions.pushList),
        getType(actions.deleteList),
      ]);

      // Verify it's the same list
      if (race.payload.listKey === listKey) {
        if (race.type === getType(actions.pushList)) {
          // List has been pushed from server; end
          break;
        }

        if (race.type === getType(actions.deleteList)) {
          // List has been deleted; cancel task and end
          yield cancel(task);
          break;
        }
      }
    }
  } catch (err) {
    yield put(actions.addListError(err));
  }
}

export function* addListTask(uid: string, listKey: string, boardKey: string, order: number) {
  try {
    yield put(actions.addListSuccess(boardKey, listKey));
    yield put(actions.addListToBoard(boardKey, listKey, order));

    // We don't use createInOrderedCollection here because at the moment
    // lists are always added to the end
    yield call(api.createObject, 'lists', uid, listKey, {
      [`boards/${boardKey}/lists/${listKey}/order`]: order,
      [`lists/${listKey}/boardKey`]: boardKey,
    });

    yield put(actions.subscribeListItems(listKey));
  } catch (e) {
    yield put(actions.addListError(e));
  }
}

export function* deleteListSaga(action: ReturnType<typeof actions.deleteList>) {
  try {
    const { boardKey, listKey, itemKeys, persist } = action.payload;
    yield put(actions.deleteListSuccess(listKey));

    if (persist) {
      // Only delete board.lists when persisting, otherwise keeping
      // in state
      yield put(actions.deleteListFromBoard(boardKey, listKey));

      let itemsToBeDeleted: { [key: string]: null } = {};
      itemKeys.map(
        itemKey =>
          (itemsToBeDeleted = Object.assign(itemsToBeDeleted, {
            [`/items/${itemKey}`]: null,
            [`/lists/${listKey}/items/${itemKey}`]: null,
          })),
      );

      yield call(api.deleteObject, 'lists', listKey, {
        ...itemsToBeDeleted,
        [`/lists/${listKey}/boardKey`]: null,
        [`/boards/${boardKey}/lists/${listKey}`]: null,
        [`/boards/${boardKey}/data/updatedAt`]: { '.sv': 'timestamp' },
      });
    }
  } catch (err) {
    yield put(actions.deleteListError(err));
  }
}

export function* moveListItemSaga(action: ReturnType<typeof actions.moveListItem>) {
  try {
    const { uid, listKey, itemKey, targetListKey, order, insertAfterKey, persist } = action.payload;

    yield put(actions.moveListItemSuccess(uid, listKey, itemKey, order, targetListKey));

    if (persist) {
      yield call(
        api.moveObjectChild,
        uid,
        'lists',
        listKey,
        targetListKey,
        'items',
        itemKey,
        insertAfterKey,
      );
    }
  } catch (err) {
    yield put(actions.moveListItemError(err));
  }
}

export function* addItemToListSaga(action: ReturnType<typeof actions.addItemToList>) {
  try {
    const { listKey, itemKey, order } = action.payload;

    yield put(actions.addItemToListSuccess(listKey, itemKey, order));
  } catch (err) {
    yield put(actions.addItemToListError(err));
  }
}

export function* pushItemToListSaga(action: ReturnType<typeof actions.pushItemToList>) {
  try {
    const { listKey, itemKey, order } = action.payload;
    yield put(actions.pushItemToListSuccess(listKey, itemKey, order));
  } catch (err) {
    yield put(actions.pushItemToListError(err));
  }
}

export function* deleteItemFromListSaga(action: ReturnType<typeof actions.deleteItemFromList>) {
  try {
    const { listKey, itemKey } = action.payload;
    yield put(actions.deleteItemFromListSuccess(listKey, itemKey));
  } catch (err) {
    yield put(actions.deleteItemFromListError(err));
  }
}

export function* listErrorsSaga(action: actions.ErrorAction) {
  yield call(console.error, action.payload.error);
}
