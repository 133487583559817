import { applyMiddleware, compose, createStore, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { getType, StateType } from 'typesafe-actions';
import * as actions from '../actions/index';
import { baseReducer, initialState } from './store.config';
import rootSaga from './sagas';

if (process.env.NODE_ENV === 'development') {
  console.log('*** Development Environment ***');
}

const appReducer = combineReducers({ ...baseReducer });

export type RootState = StateType<typeof appReducer>;

export const rootReducer = (state: RootState | undefined, action: actions.Action) => {
  let returnState: RootState | undefined;
  if (action.type === getType(actions.signOut) || state === undefined) {
    returnState = initialState;
  } else {
    returnState = state;
  }

  return appReducer(returnState, action);
};

// Sagas
const sagaMiddleware = createSagaMiddleware();

const composeEnhancers =
  ((window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
    (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({ serialize: true })) ||
  compose;

const configureStore = createStore(
  rootReducer,
  initialState,
  composeEnhancers(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default configureStore;
