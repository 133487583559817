import { getType } from 'typesafe-actions';
import * as actions from '../actions';
import { produce } from 'immer';

export type ErrorsState = {
  [container: string]: {
    [field: string]: string | undefined;
  };
};

// maybe create an error type?!
export const initialState: ErrorsState = {
  account: {
    registration: undefined,
    passwordReset: undefined,
    login: undefined,
    updateEmail: undefined,
    updatePassword: undefined,
  },
};

// Fairly simple error handling subject to change if requirements change
// We just set the most recent error message for a given error boundary (e.g. RegistrationContainer)
export const errorReducer = produce((draft: ErrorsState, action: actions.Action) => {
  switch (action.type) {
    case getType(actions.pushError): {
      const { container, field, message } = action.payload;
      draft[container] = { [field]: message };
      return;
    }

    case getType(actions.clearError): {
      const { container, field } = action.payload;
      // if only container supplied, delete all in container, otherwise only field
      const errorContainer = draft[container];

      if (errorContainer && action.payload.field) {
        delete errorContainer[field];
      } else {
        delete draft[container];
      }
      return;
    }

    case getType(actions.clearAllErrors): {
      return initialState;
    }
  }

  // Immer isn't happy with returning the original state
  // and complains about 'not all coe paths return a value'
  return;
}, initialState);
