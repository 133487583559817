import { createAction } from 'typesafe-actions';
import { IList } from '../reducers/list.reducer';

export const addList = createAction(
  'ADD_LIST',
  resolve => (uid: string, boardKey: string, index: number = 0) =>
    resolve({ uid, boardKey, index }),
);

export const addListSuccess = createAction(
  'ADD_LIST_SUCCESS',
  resolve => (boardKey: string, listKey: string) => resolve({ boardKey, listKey }),
);

export const addListError = createAction('ADD_LIST_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const deleteList = createAction(
  'DELETE_LIST',
  resolve => (
    boardKey: string,
    listKey: string,
    itemKeys: Array<string>,
    persist: boolean = false,
  ) => resolve({ boardKey, listKey, itemKeys, persist }),
);

export const deleteListSuccess = createAction('DELETE_LIST_SUCCESS', resolve => (listKey: string) =>
  resolve({ listKey }),
);

export const deleteListError = createAction('DELETE_LIST_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const updateList = createAction(
  'UPDATE_LIST',
  resolve => (uid: string, listKey: string, list: Partial<IList>, persist: boolean) =>
    resolve({ uid, listKey, list, persist }),
);

export const updateListSuccess = createAction(
  'UPDATE_LIST_SUCCESS',
  resolve => (listKey: string, list: Partial<IList>) => resolve({ listKey, list }),
);

export const updateListError = createAction('UPDATE_LIST_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const pushList = createAction(
  'PUSH_LIST',
  resolve => (boardKey: string, listKey: string, list: Partial<IList>, index: number = -1) =>
    resolve({ boardKey, listKey, list, index }),
);

export const pushListSuccess = createAction(
  'PUSH_LIST_SUCCESS',
  resolve => (listKey: string, list: Partial<IList>) => resolve({ listKey, list }),
);

export const pushListError = createAction('PUSH_LIST_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const subscribeList = createAction(
  'SUBSCRIBE_LIST',
  resolve => (boardKey: string, listKey: string) => resolve({ boardKey, listKey }),
);

export const subscribeListError = createAction('SUBSCRIBE_LIST_ERROR', resolve => (error: Error) =>
  resolve({ error }),
);

export const unsubscribeList = createAction('UNSUBSCRIBE_LIST', resolve => (listKey: string) =>
  resolve({ listKey }),
);

export const subscribeListItems = createAction(
  'SUBSCRIBE_LIST_ITEMS',
  resolve => (listKey: string) => resolve({ listKey }),
);

export const subscribeListItemsError = createAction(
  'SUBSCRIBE_LIST_ITEMS_ERROR',
  resolve => (error: Error) => resolve({}),
);

export const addItemToList = createAction(
  'ADD_ITEM_TO_LIST',
  resolve => (listKey: string, itemKey: string, order: number) =>
    resolve({ listKey, itemKey, order }),
);

export const addItemToListSuccess = createAction(
  'ADD_ITEM_TO_LIST_SUCCESS',
  resolve => (listKey: string, itemKey: string, order: number) =>
    resolve({ listKey, itemKey, order }),
);

export const addItemToListError = createAction(
  'ADD_ITEM_TO_LIST_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushItemToList = createAction(
  'PUSH_ITEM_TO_LIST',
  resolve => (listKey: string, itemKey: string, order: number) =>
    resolve({ listKey, itemKey, order }),
);

export const pushItemToListSuccess = createAction(
  'PUSH_ITEM_TO_LIST_SUCCESS',
  resolve => (listKey: string, itemKey: string, order: number) =>
    resolve({ listKey, itemKey, order }),
);

export const pushItemToListError = createAction(
  'PUSH_ITEM_TO_LIST_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const deleteItemFromList = createAction(
  'DELETE_ITEM_FROM_LIST',
  resolve => (listKey: string, itemKey: string) => resolve({ listKey, itemKey }),
);

export const deleteItemFromListSuccess = createAction(
  'DELETE_ITEM_FROM_LIST_SUCCESS',
  resolve => (listKey: string, itemKey: string) => resolve({ listKey, itemKey }),
);

export const deleteItemFromListError = createAction(
  'DELETE_ITEM_FROM_LIST_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const moveListItem = createAction(
  'MOVE_BOARD_LIST_ITEM',
  resolve => (
    uid: string,
    listKey: string,
    itemKey: string,
    order: number,
    insertAfterKey: string | undefined,
    persist: boolean = false,
    targetListKey: string | undefined,
  ) =>
    resolve({
      uid,
      listKey,
      itemKey,
      order,
      insertAfterKey,
      targetListKey,
      persist,
    }),
);

export const moveListItemSuccess = createAction(
  'MOVE_BOARD_LIST_ITEM_SUCCESS',
  resolve => (
    uid: string,
    listKey: string,
    itemKey: string,
    order: number,
    targetListKey?: string,
  ) =>
    resolve({
      uid,
      listKey,
      itemKey,
      order,
      targetListKey,
    }),
);

export const moveListItemError = createAction(
  'MOVE_BOARD_LIST_ITEM_ERROR',
  resolve => (error: Error) => resolve({ error }),
);
