import { createAction } from 'typesafe-actions';
import { IUserBoard, UserBoardsState } from 'reducers/userBoards.reducer';

export const subscribeUserBoards = createAction('SUBSCRIBE_USER_BOARDS', resolve => <
  K extends keyof IUserBoard
>(
  uid: string,
  filterBy: K,
  limit: number = 10,
  sort: 'ASC' | 'DESC',
  filterValue?: IUserBoard[K] | number, // not the best style, but it'll work for now
  childKey?: 'order',
) => resolve({ uid, limit, filterBy, filterValue, sort, childKey }));

export const subscribeUserBoardsError = createAction(
  'SUBSCRIBE_BOARD_EXPIRY_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushUserBoards = createAction(
  'PUSH_USER_BOARDS',
  resolve => (boards: UserBoardsState) => resolve({ boards }),
);

export const pushUserBoardsSuccess = createAction(
  'PUSH_USER_BOARDS_SUCCESS',
  resolve => (boards: UserBoardsState) => resolve({ boards }),
);

export const pushUserBoardsError = createAction(
  'SUBSCRIBE_USER_BOARDS_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const addBoardToUserBoards = createAction(
  'ADD_BOARD_TO_USER_BOARDS',
  resolve => (boardKey: string, userBoard: IUserBoard) =>
    resolve({
      boardKey,
      userBoard,
    }),
);

export const addBoardToUserBoardsSuccess = createAction(
  'ADD_BOARD_TO_USER_BOARDS_SUCCESS',
  resolve => (boardKey: string, userBoard: IUserBoard) =>
    resolve({
      boardKey,
      userBoard,
    }),
);

export const addBoardToUserBoardsError = createAction(
  'ADD_BOARD_TO_USER_BOARDS_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const pushBoardToUserBoards = createAction(
  'PUSH_BOARD_TO_USER_BOARDS',
  resolve => (boardKey: string, userBoard: IUserBoard) =>
    resolve({
      boardKey,
      userBoard,
    }),
);

export const pushBoardToUserBoardsSuccess = createAction(
  'PUSH_BOARD_TO_USER_BOARDS_SUCCESS',
  resolve => (boardKey: string, userBoard: IUserBoard) =>
    resolve({
      boardKey,
      userBoard,
    }),
);

export const pushBoardToUserBoardsError = createAction(
  'PUSH_BOARD_TO_USER_BOARDS_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const updateUserBoard = createAction(
  'UPDATE_USER_BOARD',
  resolve => (uid: string, boardKey: string, userBoard: Partial<IUserBoard>) =>
    resolve({
      uid,
      boardKey,
      userBoard,
    }),
);

export const updateUserBoardSuccess = createAction(
  'UPDATE_USER_BOARD_SUCCESS',
  resolve => (boardKey: string, userBoard: Partial<IUserBoard>) =>
    resolve({
      boardKey,
      userBoard,
    }),
);

export const updateUserBoardError = createAction(
  'UPDATE_USER_BOARD_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const deleteBoardFromUserBoards = createAction(
  'DELETE_BOARD_FROM_USER_BOARDS',
  resolve => (boardKey: string) => resolve({ boardKey }),
);
export const deleteBoardFromUserBoardsSuccess = createAction(
  'DELETE_BOARD_FROM_USER_BOARDS_SUCCESS',
  resolve => (boardKey: string) => resolve({ boardKey }),
);
export const deleteBoardFromUserBoardsError = createAction(
  'DELETE_BOARD_FROM_USER_BOARDS_ERROR',
  resolve => (error: Error) => resolve({ error }),
);

export const moveUserBoard = createAction(
  'MOVE_USER_BOARD',
  resolve => (
    uid: string,
    filter: keyof IUserBoard,
    boardKey: string,
    order: number,
    insertAfterKey: string | undefined,
    persist: boolean,
  ) =>
    resolve({
      uid,
      filter,
      boardKey,
      order,
      insertAfterKey,
      persist,
    }),
);

export const moveUserBoardSuccess = createAction(
  'MOVE_USER_BOARD_SUCCESS',
  resolve => (
    filter: keyof IUserBoard,
    boardKey: string,
    order: number,
    insertAfterKey: string | undefined,
    persist: boolean,
  ) =>
    resolve({
      filter,
      boardKey,
      order,
      insertAfterKey,
      persist,
    }),
);

export const moveUserBoardError = createAction('MOVE_USER_BOARD_ERROR', resolve => (error: Error) =>
  resolve({
    error,
  }),
);
